.location-box {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: calc(50vw + #{($container-max-width / 2) + $container-padding});
    max-width: calc(50vw + #{($container-max-width / 2) + $container-padding} - (var(--scrollbarWidth) / 2));

    padding-right: $container-padding;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        padding-right: 0;
        max-width: none;
    }

    &.location-selected {
        align-items: stretch;

        @include media-breakpoint-only(md) {
            padding-right: 40px;
        }

        @include media-breakpoint-down(md) {
            flex-wrap: wrap-reverse;
        }

        .map-container {
            height: auto;

            @include media-breakpoint-down(md) {
                height: calc(100vh - #{$header-top-height--mobile});
            }
        }
    }

    .map-container {
        flex: 1 0;
        @include fluid-property('margin-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 50px, 100px);
        height: 400px;
        max-width: $container-max-width--narrow;
        z-index: 1;

        @include media-breakpoint-only(md) {
            max-width: none;
            flex: 0 0 60%;
            margin-right: 30px;
            width: 60%;
        }

        @include media-breakpoint-down(md) {
            flex-basis: 100%;
            width: 100%;
            max-width: none;
            height: 0;
            padding-bottom: 100%;
            order: 1;
        }

        .custom-tooltip {
            padding: 0;
            margin: 0;
            background: none transparent;
            border: none;
            border-radius: 0;
            color: $black;
            font-size: 18px;
            font-weight: $font-weight-bold;
            font-family: $font-family-special;
            box-shadow: none;

            @include media-breakpoint-down(md) {
                width: 110px;
                white-space: normal;
                line-height: 1.2;
            }

            &.leaflet-tooltip {
                &-center {
                    @include media-breakpoint-down(md) {
                        text-align: center;
                    }
                }

                &-left {
                    @include media-breakpoint-down(md) {
                        text-align: right;
                    }
                }
            }

            &:before {
                display: none;
            }

            &--city {
                text-transform: uppercase;
                font-size: 20px;

                @include media-breakpoint-down(md) {
                    font-size: 14px;
                }
            }

            &.selected {
                font-size: 25px;
                text-transform: uppercase;

                @include media-breakpoint-down(md) {
                    font-size: 18px;
                    width: auto;
                    white-space: nowrap;
                }
            }
        }
    }

    &--nav {
        position: relative;
        flex: 0 0;
        z-index: 2;

        @include media-breakpoint-only(md) {
            flex-basis: 40%;
            width: 40%;
        }

        @include media-breakpoint-down(md) {
            order: 0;
            width: 100%;
            flex-basis: 100%;
        }

        ul {
            @include reset-list;

            @include media-breakpoint-down(md) {
                display: flex;
                margin-left: -5px;
                margin-right: -5px;
                padding: 0 30px;
            }

            @media (max-width: 374px) {
                padding: 0 15px;
            }

            li {
                margin-bottom: 22px;

                @include media-breakpoint-down(md) {
                    flex: 0 0 25%;
                    margin-bottom: 25px;
                    padding: 0 5px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    position: relative;
                    display: block;
                    @include fluid-property('padding-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 100px);
                    @include fluid-property('width', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 350px, 430px);
                    height: 70px;
                    line-height: 70px;
                    background: $brand-primary;
                    color: $black;
                    white-space: nowrap;
                    font-size: 25px;
                    font-family: $font-family-special;
                    font-weight: $font-weight-bold;
                    transition: padding .3s;

                    @include media-breakpoint-only(md) {
                        padding-left: 30px;
                        width: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        display: flex;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: center;
                        padding: 0;
                        width: 100%;
                        height: 165px;
                        line-height: 1.2;
                        font-size: 18px;
                        white-space: normal;
                        text-align: center;
                    }

                    .icon {
                        position: relative;
                        top: -3px;
                        @include fluid-property('margin-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 40px);
                        width: 22px;
                        height: 30px;
                        vertical-align: middle;

                        @include media-breakpoint-only(md) {
                            margin-right: 20px;
                        }

                        @include media-breakpoint-down(md) {
                            display: block;
                            top: 0;
                            margin: 0 0 15px;
                            width: 100%;
                            height: 24px;
                        }
                    }

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        right: 100%;
                        top: 0;
                        margin-right: -1px; // workaround für pixel-fehler bei transition
                        height: 100%;
                        @include fluid-property('width', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 120px, 170px);
                        background: $brand-primary;
                        transform: scaleX(0);
                        transition: transform .3s;
                        transform-origin: 100% 0;
                        will-change: transform;

                        @include media-breakpoint-down(md) {
                            right: auto;
                            left: 0;
                            top: 100%;
                            margin: 0;
                            height: 65px;
                            width: 100%;
                            transform: scale(1, 0);
                        }
                    }

                    &:hover:not(.is-active) {
                        @include fluid-property('padding-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 40px, 80px);

                        @include media-breakpoint-only(md) {
                            padding-left: 15px;
                        }

                        @include media-breakpoint-down(md) {
                            padding-left: 0;
                        }

                        &:before  {
                            @include media-breakpoint-up(lg) {
                                transform: scaleX(.2);
                            }

                            @include media-breakpoint-only(md) {
                                transform: scaleX(.15);
                            }
                        }
                    }

                    &.is-active {
                        @include media-breakpoint-up(lg) {
                            padding-left: 20px;
                        }

                        @include media-breakpoint-only(md) {
                            padding-left: 0;
                        }

                        &:before {
                            @include media-breakpoint-up(lg) {
                                transform: scaleX(1);
                            }

                            @include media-breakpoint-only(md) {
                                transform: scaleX(.6);
                            }

                            @include media-breakpoint-down(md) {
                                transform: scale(1, 1);
                            }
                        }
                    }
                }
            }
        }
    }

    &--content {
        padding: 50px 0;
        @include fluid-property('max-width', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 350px, 410px);

        @include media-breakpoint-down(md) {
            padding: 50px 40px 0;
            width: 100%;
            max-width: none;
        }

        h3 {
            margin-bottom: .8em;
        }

        a:not(.btn) {
            &, &:hover {
                color: $white;
            }
        }

        ul {
            list-style-type: none;
            margin: 1em 0;
            padding: 0;

            li {
                position: relative;
                padding-left: 35px;

                .icon {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}