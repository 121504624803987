.area--section-header {
    padding-right: $container-padding;

    @include media-breakpoint-down(lg) {
        padding-right: 0;
    }

    &--inner {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        max-width: calc(50vw + (#{$container-max-width--narrow} / 2));
        max-width: calc(50vw + (#{$container-max-width--narrow} / 2) - (var(--scrollbarWidth) / 2));

        @include media-breakpoint-down(lg) {
            max-width: none;
        }

        > * {
            flex: 1 1 auto;
        }
    }

    &--icon {
        padding: 50px 20px;
        text-align: center;
        min-width: 220px;
        background: $brand-primary;

        @include media-breakpoint-only(md) {
            flex: 0 0 auto;
            width: 250px;
            height: 250px;
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-wrap: wrap;
            flex: 1 0 50%;
            align-items: center;
            align-content: center;
            justify-content: center;
            padding: 10px;
            min-width: 0;
            width: 50%;
            max-width: 190px;
        }

        .icon {
            width: 80px;
            height: 80px;
            filter: saturate(0) brightness(0) invert(0) opacity(1);

            @include media-breakpoint-down(md) {
                width: 60px;
                height: 60px;
            }
        }

        h1, h2, h3 {
            margin-bottom: 1em;
            color: $black;
            text-transform: uppercase;
            @include fluid-type($viewport-width-min, $viewport-width-max, 30px, 40px);

            @include media-breakpoint-down(md) {
                width: 100%;
                font-size: 18px;
            }
        }
    }

    &--image {
        position: relative;
        width: 100%;
        max-width: $container-max-width--narrow;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            height: auto;
            max-height: 190px;
        }

        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 0;
            padding-bottom: 31.57895%;

            @include media-breakpoint-down(md) {
                padding-bottom: 100%;
            }
        }

        img, video {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}