.tab-nav {
    @include reset-list;
    border-bottom: 1px solid $brand-primary;

    .is-highlighted:not(.white) & {
        border-bottom-color: $black;
    }

    .tab-item {
        display: inline-block;
        padding: 0;

        &:before {
            display: none;
        }

        .tab-link {
            display: block;
            background: transparent;
            padding: 8px 15px;
            color: $brand-primary;
            font-weight: $font-weight-bold;
            text-decoration: none;
            font-size: 25px;
            border-radius: 5px 5px 0 0;
            transition: background-color .3s, color .3s;

            &:hover {
                color: rgba($brand-primary, .6);
            }

            &.active {
                background-color: $brand-primary;
                color: $black;
            }

            .is-highlighted:not(.white) & {
                color: $black;

                &.active {
                    color: $white;
                    background-color: $black;
                }
            }
        }
    }
}

.tab-content {
    > .tab-pane {
        display: none;
    }
    > .active {
        display: block;
    }
}