.pagination {
    ul {
        @include reset-list;

        li {
            display: inline-block;
            margin: 0 10px 7px 0;

            &:last-child {
                margin-right: 0;
            }

            a {
                display: block;
                width: 50px;
                height: 50px;
                line-height: 48px;
                border: 1px solid $brand-primary;
                border-radius: $btn-border-radius;
                text-align: center;
                transition: color .3s, background-color .3s;
                white-space: nowrap;

                &:hover:not(.is-active) {
                    background: rgba($brand-primary, .3);
                    color: $white;
                }

                &.is-active {
                    background: $brand-primary;
                    color: $black;
                }

                &.prev-next {
                    padding: 0 15px;
                    width: auto;
                }
            }
        }
    }
}