.area--text {

    .main-content > & {
        padding-left: $container-padding;
        padding-right: $container-padding;

        @include media-breakpoint-down(sm) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: .5em;
    }
}