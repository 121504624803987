.darkpage {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: $container-padding;
    background: rgba(0, 0, 0, .8);
    z-index: 99999;
    opacity: 0;
    visibility: visible;
    transition: opacity .35s, visibility .35s;
    pointer-events: none;

    @include media-breakpoint-down(md) {
        padding: 40px;
    }

    &.is-active {
        display: block;
    }

    .darkpage--visible & {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    &--close {
        position: absolute;
        top: 25px;
        right: 25px;
        background: none;

        @include media-breakpoint-down(md) {
            top: 18px;
            right: 15px;
        }
    }

    $darkpage-padding-y: 90px;
    &--container {
        position: absolute;
        left: 50%;
        top: 50%;
        padding: #{90px - 10px} 75px 90px;
        max-width: 1280px;
        width: calc(100vw - #{$container-padding * 2});
        max-height: calc(100vh - #{$container-padding * 2});
        transform: translate(-50%, -50%);
        background: $brand-primary;

        @include media-breakpoint-down(md) {
            padding: 35px 20px 30px;
            width: calc(100vw - 80px);
            max-height: calc(100vh - 80px);
        }

        &, p {
            color: $black;
        }

        header {
            position: relative;

            p, div[data-name="darkpage_title"] {
                margin-bottom: to-em(25px, $h1-font-size);
                padding: 0 0 to-em(20px, $h1-font-size) to-em(110px, $h1-font-size);
                border-bottom: 2px solid $black;
                color: $black;

                @include media-breakpoint-down(md) {
                    margin-bottom: 15px;
                    padding: 0 0 15px 75px;
                    min-height: 70px;
                }

                @media (max-width: 374px) {
                    padding-left: 65px;
                    min-height: 60px;
                }

                &.h1,
                &.h2,
                &.h3,
                &.h4 {
                    @include media-breakpoint-down(md) {
                        font-size: 20px;
                    }
                }
            }

            &.no-icon {
                p, div[data-name="darkpage_title"] {
                    padding-left: 0;
                }
            }

            .icon {
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: to-em(-10px, $h1-font-size);
                width: to-em(80px, $h1-font-size);
                height: to-em(80px, $h1-font-size);
                transform: translateY(-50%);
                filter: saturate(0) brightness(0) invert(0) opacity(1);

                @include media-breakpoint-down(md) {
                    margin-top: -12px;
                    width: 60px;
                    height: 60px;
                }

                @media (max-width: 374px) {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

        &--inner {
            padding-top: 10px;
            max-height: calc(100vh - #{$container-padding * 2} - #{$darkpage-padding-y * 2});
            overflow: hidden;
            overflow-y: auto;

            @include media-breakpoint-down(md) {
                max-height: calc(100vh - 80px - 35px - 30px);
            }

            p {
                &.lead {
                    font-size: 20px;
                    font-weight: $font-weight-bold;
                }

                a:not(.btn) {
                    color: $black;
                    text-decoration: underline;
                }

                .btn {
                    &-primary,
                    &-secondary {
                        border-color: $black;
                    }
                }
            }

            > p {
                @include media-breakpoint-down(md) {
                    font-size: 15px;
                }
            }
        }
}