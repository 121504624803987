.area {
    @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 30px, 60px);
    @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 30px, 60px);

    // individuelle Abstände
    &.margin {
        $margin--tiny: 10px;
        $margin--small: 20px;
        $margin--medium-min: 40px;
        $margin--medium-max: 60px;
        $margin--large-min: 65px;
        $margin--large-max: 100px;
        $margin--xlarge-min: 80px;
        $margin--xlarge-max: 125px;

        &-top--none {
            margin-top: 0;
        }

        &-top--tiny {
            margin-top: $margin--tiny;
        }

        &-top--small {
            margin-top: $margin--small;
        }

        &-top--medium {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, $margin--medium-min, $margin--medium-max);
        }

        &-top--large {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, $margin--large-min, $margin--large-max);
        }

        &-top--xlarge {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, $margin--xlarge-min, $margin--xlarge-max);
        }

        &-bottom--none {
            margin-bottom: 0;
        }

        &-bottom--tiny {
            margin-bottom: $margin--tiny;
        }

        &-bottom--small {
            margin-bottom: $margin--small;
        }

        &-bottom--medium {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, $margin--medium-min, $margin--medium-max);
        }

        &-bottom--large {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, $margin--large-min, $margin--large-max);
        }

        &-bottom--xlarge {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, $margin--xlarge-min, $margin--xlarge-max);
        }
    }

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }

    &.is-narrow {
        margin-left: auto;
        margin-right: auto;
        padding-left: $container-padding;
        padding-right: $container-padding;
        max-width: $container-max-width--narrow + ($container-padding * 2);

        @include media-breakpoint-down(sm) {
            padding-left: 40px;
            padding-right: 40px;
        }

        .area.is-narrow {
            padding-left: 0;
            padding-right: 0;
            max-width: none;
        }
    }

    &.full-width {
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);
    }

    &--thumbnail {
        img, video {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    a:not(.btn) {
        text-decoration: underline;
        transition: color .3s;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0 0 1em;

        ul {
            margin: 0 0 0 .5em;
        }

        li {
            position: relative;
            padding-left: to-em(15px, $font-size-base);

            &:before {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: to-em(6px, $font-size-base);
                height: $line-height-base + 0em;
                font-size: 1em;
                @include updateIcon(arrow, '');
                background-repeat: no-repeat;
                background-position: 0 50%;
                background-size: contain;
            }
        }
    }

    &.is-highlighted {
        color: $black;

        &:not(.white) {
            a:not(.btn) {
                color: $white;

                &:hover, &:focus {
                    color: rgba($white, .6);
                }
            }

            ul {
                li {
                    &:before {
                        filter: saturate(0) brightness(0) invert(0) opacity(1);
                    }
                }
            }
        }
    }

    &.has-anchor {
        @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $header-top-height--min-scrolled, $header-top-height-scrolled);

        @include media-breakpoint-only(md) {
            padding-top: $header-top-height--tablet-scrolled;
        }

        @include media-breakpoint-down(md) {
            padding-top: $header-top-height--mobile-scrolled;
        }
    }
}