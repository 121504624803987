.area--separator {
    position: relative;
    pointer-events: none;
    min-height: 1px;

    .icon {
        position: absolute;
        bottom: 0;
        right: $container-padding;
        @include fluid-type(map-get($grid-breakpoints, md), map-get($grid-breakpoints, xl), 80px, 135px);
        background-position: center bottom;
        filter: saturate(0) brightness(0) invert(100%) opacity(1);

        @include media-breakpoint-up(xxxl) {
            right: 50%;
            transform: translateX(760px);
        }

        @include media-breakpoint-down(sm) {
            right: 40px;
        }
    }
}