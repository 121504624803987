.area--icon-teaser {

    &--wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);
    }
}

.icon-teaser {
    flex: 1 1;
    position: relative;
    padding: 0 ($grid-gutter-width / 2) $grid-gutter-width * 2;
    max-width: 284px;
    min-width: 200px;
    text-align: center;

    @include media-breakpoint-only(md) {
        max-width: 33.3333%;
        min-width: 0;
        flex-basis: 33.3333%;
    }

    @include media-breakpoint-down(md) {
        max-width: none;
        min-width: 0;
        width: 50%;
        flex-basis: auto;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    &:after {
        display: block;
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: ($grid-gutter-width * 2) + 10px;
        border-right: 1px solid $brand-primary;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &:last-child {
        &:after {
            display: none;
        }
    }

    &:nth-child(2n) {
        &:after {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &:nth-child(3n) {
        &:after {
            @include media-breakpoint-only(md) {
                display: none;
            }
        }
    }

    a:not(.btn) {
        display: block;
        color: $white;
        text-decoration: none;
    }

    .icon {
        margin-bottom: 30px;
        @include fluid-property('width', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 80px);
        height: auto;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }

        &:after {
            display: block;
            content: '';
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
    }

    &--text {

        p {
            margin: 0;
        }
    }
}