.area--anchors {

  @include media-breakpoint-down(md) {
    position: sticky;
    top: 50px;
    margin-top: 40px;
    background-color: $black;
    z-index: 9;

    .no-media {
      margin-top: 15px;
    }
  }


  .page-anchor-menu {
    width: 100vw;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(md) {
        padding-top: 15px;
        padding-bottom: 20px;
        justify-content: unset;
        overflow-x: scroll;
      }
    }

    li {
      list-style-type: none;
      white-space: nowrap;
      position: relative;
      font-family: $font-family-special;
      @include fluid-property('padding-left', $viewport-width-min, $viewport-width-max, 20px, 25px);
      @include fluid-property('padding-right', $viewport-width-min, $viewport-width-max, 20px, 25px);
      @include fluid-property('line-height', $viewport-width-min, $viewport-width-max, 25px, 30px);
      @include fluid-type($viewport-width-min, $viewport-width-max, 20px, 25px);

      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
      }

      &:before {
        display: none;
      }

      a {
        color: $white;
        text-decoration: none !important;

        &:hover ~ svg {

          g {
            opacity: 1;
          }

          path {
            fill: $brand-primary;
            stroke: $brand-primary;
          }
        }
      }

      svg {
        position: absolute;
        width: 100%;
        bottom: -.8em;
        left: 50%;
        transform: translateX(-50%);
      }

      &:not(:last-child) {
        border-right: 1px dashed $white;
      }
    }
  }
}

.subpage .page-anchor-menu {
  @include media-breakpoint-up(md) {
    top: 70vh;
  }
}