$page-overlay--z-index: 9998;

$header-top-height: 180px;
$header-top-height--tablet: 100px;
$header-top-height--mobile: 70px;
$header-top-height--min: 130px;

$header-top-height-scrolled: 100px;
$header-top-height--tablet-scrolled: 60px;
$header-top-height--mobile-scrolled: 50px;
$header-top-height--min-scrolled: 85px;

$header-logo-width: 340px;
$header-logo-width--tablet: 180px;
$header-logo-width--mobile: 130px;