.area--textmedia {
    > * {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }

        > * {
            width: 50%;
            flex: 1 1 auto;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }



        @include media-breakpoint-up(lg) {
            &.media-width {
                &--75 {
                    .area--thumbnail { width: 75%; }
                    .area--text { width: 25%; }

                }
                &--60 {
                    .area--thumbnail { width: 60%; }
                    .area--text { width: 40%; }
                }
                &--40 {
                    .area--thumbnail { width: 40%; }
                    .area--text { width: 60%; }
                }
                &--25 {
                    .area--thumbnail { width: 25%; }
                    .area--text { width: 75%; }
                }
            }
        }
    }

    &--text .area--text--inner {
        overflow-x: hidden;
    }

    .area--thumbnail {
        margin: 0;
    }

    .area--text {
        display: flex;
        position: relative;
        padding: 50px;
        @include fluid-property('padding-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 100px);
        @include fluid-property('padding-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 100px);

        @include media-breakpoint-only(md) {
            padding-left: 50px;
            padding-right: 50px;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 40px;
            padding-right: 40px;
        }

        &--inner {
            padding-top: 0;
            width: 100%;
            max-width: 420px;

            @include media-breakpoint-down(md) {
                max-width: none;
            }

            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    .icon ~ .area--text--inner {
        @include media-breakpoint-down(md) {
            padding-top: 55px;
        }
    }

    /*
    &:not(.is-highlighted) {
        .area--text {
            @include media-breakpoint-down(md) {
                padding-bottom: 0;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .thumbnail--right {
            .area--text {
                @include media-breakpoint-down(md) {
                    padding-top: 0;
                    padding-bottom: 50px;
                }
            }
        }
    }*/

        .icon {
            position: absolute;
            bottom: 100%;
            @include fluid-property('right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 50px);
            @include fluid-property('width', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 135px);
            height: auto;
            transform: translateY(45%);

            @include media-breakpoint-between(sm, md) {
                left: $container-padding;
            }

            @include media-breakpoint-down(md) {
                right: auto;
                width: 80px;
                height: 80px;
                transform: translateY(60%);
            }

            @include media-breakpoint-down(sm) {
                left: 40px;
            }

            &:before {
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 100%;
            }
        }

        .btn-special {
            margin-top: to-em(30px, $btn-special--font-size);

            @include media-breakpoint-down(md) {
                float: right;
                margin-top: 12px;

                &,
                &:hover {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

    // farbig hinterlegt
    &.is-highlighted {
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);

        .area--text {
            background: $brand-primary;

            .btn-primary {
                &, &:before {
                    border-color: $white;
                }
            }
        }

        &.white {
            .area--text {
                background: $white;
            }
        }

        &:not(.white) {
            .icon {
                filter: saturate(0) brightness(0) invert(100%) opacity(1);
            }
        }

        .icon ~ .area--text--inner {
            @include media-breakpoint-down(md) {
                padding-top: 25px;
            }
        }
    }

    .thumbnail--right {
        flex-direction: row-reverse;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap-reverse;
        }

        .area--text {
            justify-content: flex-end;
        }

        .icon {
            right: auto;
            @include fluid-property('left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 50px);

            @include media-breakpoint-between(sm, md) {
                left: $container-padding;
            }
            @include media-breakpoint-down(sm) {
                left: 40px;
            }
        }

        @include media-breakpoint-up(md) {
            .btn-special {
                margin-left: to-em(-60px, $btn-special--font-size);
                text-align: right;

                &:hover {
                    margin-left: 0;
                }

                &:after {
                    left: auto;
                    right: 100%;
                    margin-left: auto;
                    margin-right: -1px;
                }
            }
        }
    }

    // spezial text+bild mit teasern
    &.is-special {
        > * {
            align-items: stretch;
        }

        .area--text {
            flex-wrap: wrap;
            padding-bottom: 0;
            padding-top: 0;
        }

        .thumbnail--left {
            .area--text {
                @include media-breakpoint-up(md) {
                    @include fluid-property('padding-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 15px, 30px);

                    &--inner {
                        @include fluid-property('margin-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 45px, 70px);
                    }
                }

                @include media-breakpoint-down(md) {
                    padding-top: 30px;
                }
            }

            .btn-special {
                @include media-breakpoint-up(md) {
                    margin-right: 0;
                    padding-right: 0;
                    float: right;

                    &:hover {
                        margin-right: 1em;
                    }
                }
            }
        }

        .area--thumbnail {
            position: relative;

            img,
            video,
            iframe {
                @include media-breakpoint-up(md) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .btn-special {
            width: auto;
        }

        .thumbnail--right {
            .area--text {
                @include media-breakpoint-up(md) {
                    @include fluid-property('padding-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 15px, 30px);

                    &--inner {
                        @include fluid-property('margin-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 45px, 70px);
                    }
                }
            }

            .btn-special {
                @include media-breakpoint-up(md) {
                    margin-left: 0;
                    padding-left: 0;

                    &:hover {
                        margin-left: 1em;
                    }
                }
            }
        }

        [class*="thumbnail--"] {
            @include media-breakpoint-only(md) {
                position: relative;
                padding-bottom: calc((100vw / 3) - (100px / 3) - (60px / 3) + 50px);
                padding-bottom: calc((100vw / 3) - (100px / 3) - (60px / 3) - (var(--scrollbarWidth) / 3) + 50px);
            }
        }
    }

        &--teaser {
            display: flex;
            @include fluid-property('margin-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 130px);
            width: 100%;

            @include media-breakpoint-only(md) {
                position: absolute;
                //left: 50px;
                left: -100%;
                top: 100%;
                margin-top: 50px;
                margin-left: 40px;
                width: calc(100vw - 100px);
                width: calc(100vw - 100px - var(--scrollbarWidth));
            }

            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
                justify-content: center;
                margin-left: -47px;
                margin-right: -47px;
                width: 100vw;
                width: calc(100vw - var(--scrollbarWidth));
            }

            .teaser {
                position: relative;
                flex: 1 1 33.3333%;
                @include fluid-property('margin-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, outer), ($grid-gutter-width / 4), ($grid-gutter-width / 2));
                @include fluid-property('margin-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, outer), ($grid-gutter-width / 4), ($grid-gutter-width / 2));
                width: 33.3333%;
                font-size: 25px;
                @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, outer), 18px, 25px);
                font-family: $font-family-special;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                text-align: center;

                @include media-breakpoint-only(md) {
                    margin: 0 15px;
                }

                @include media-breakpoint-down(sm) {
                    flex: 0 0 50%;
                    width: auto;
                    margin: 0 0 14px;
                    padding-left: 7px;
                    padding-right: 7px;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:after {
                    display: block;
                    content: '';
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                }

                &--inner {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    padding: 10px;
                    width: 100%;
                    height: 100%;
                    background: $brand-primary;
                    color: $black;

                    @include media-breakpoint-down(sm) {
                        left: 7px;
                        right: 7px;
                        width: auto;
                    }
                }

                p {
                    margin: 0 0 to-em(15px, 25px);
                    width: 100%;
                    line-height: 1.2;
                }

                .icon {
                    position: static;
                    width: to-em(80px, 25px);
                    height: to-em(80px, 25px);
                    transform: none;
                    filter: saturate(0) brightness(0) invert(0) opacity(1);
                }
            }
        }
}