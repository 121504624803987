.search {
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding;
    padding-right: $container-padding;
    max-width: $container-max-width--narrow + ($container-padding * 2);

    @include media-breakpoint-down(sm) {
        padding-left: 40px;
        padding-right: 40px;
    }

    &--form {
        position: relative;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-right: 55px;
        width: 400px;
        max-width: 100%;

        input[type="text"] {
            border: none;
            border-radius: 5px;
            padding: 0 15px;
            width: 100%;
            height: 50px;
        }

        .btn {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            width: 50px;
            height: 50px;
            font-size: 28px;

            .icon {
                margin: 0;
            }
        }
    }

    &--results-count {
        text-align: center;
        margin-bottom: 2em;
    }

    &--results {

        hr {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 30px, 60px);
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 30px, 60px);
        }
    }

        &--result {

            header {
                margin-bottom: 1em;
            }

            &--footer {
                span {
                    display: block;
                }
            }
        }

    &--pagination {
        @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 30px, 60px);
    }
}