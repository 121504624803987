.white-layout {
    background-color: $white;
    color: $black;

    h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
        color: $black;
    }

    .page-header {
        background-color: $white;

        &--top {
            background: $white;

            .meta-nav > ul > li {

                & > label, & > a {
                    color: $black;
                }
            }

            .main-nav--toggle.burger span {
                background: $black;

                &:before, &:after {
                    background: $black;
                }
            }

            .header-search--toggle .icon {
                filter: brightness(0) saturate(100%);
            }
        }

        .lang-nav a {
            color: $black;
        }

        &--landingpage > a {
            color: $black;
        }
    }

    .area--text table thead tr:first-child th {
        border-color: $white;
    }

    .accordion-header .btn {
        color: $black;
    }

    .footer--main {

        h4 {
            color: $black;
        }

        .footer--links ul li a {
            color: $black;
        }

        .footer--logos a {

            &:first-child img {
                background: $black;
            }

            &:nth-child(2) img {
                filter: unset;
            }
        }

        .logo, .footer--social i {
            filter: none;
        }
    }

    .dropzone {
        border-color: $black;
        color: $black;
    }
}