.accordion {
    border-bottom: 1px solid rgba(255, 255, 255, .2);

    .is-highlighted & {
        border-bottom-color: rgba(0, 0, 0, .2);
    }

    &.active {
        .accordion-content {
            max-height: 5000px;
            transition: max-height 1s ease-in;
        }

        .accordion-header {
            &:after {
                transform: translateY(-50%) rotateZ(90deg);
            }
        }
    }

    &-header {
        position: relative;

        .btn {
            padding: to-em(14px, 25px) 0;
            display: block;
            color: $white;
            font-size: 25px;
            border: none;
            text-align: left;
            width: 100%;

            .is-highlighted & {
                color: $black;
            }
        }

        &:after {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            right: 1em;
            width: 8px;
            height: 14px;
            transform: translateY(-50%);
            @include updateIcon('arrow', '');
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            transition: transform .3s;
            pointer-events: none;

            .is-highlighted:not(.white) & {
                filter: saturate(0) brightness(0) invert(0) opacity(1);
            }
        }
    }

    &-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height .8s ease-out;
    }

    &-body {
        padding-bottom: 1em;

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}