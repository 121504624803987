.area--content-container {

    &.is-highlighted {
        &:not(.white) {
            a:not(.btn) {
                color: $white;

                &:hover {
                    color: rgba($white, .8);
                }
            }
        }

        .area--content-container--inner {
            background: $brand-primary;
            color: $black;

            > * {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &.white {
            .area--content-container--inner {
                background: $white;
            }
        }

        &.is-narrow {
            @include media-breakpoint-down(lg) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &--inner {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @include fluid-property('padding-top', $viewport-width-min, $viewport-width-max, 50px, 100px);
        @include fluid-property('padding-bottom', $viewport-width-min, $viewport-width-max, 50px, 100px);

        @include fluid-property('padding-left', $viewport-width-min, $viewport-width-max, 35px, 70px);
        @include fluid-property('padding-right', $viewport-width-min, $viewport-width-max, 35px, 70px);

        @include media-breakpoint-down(md) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .small-padding & {
            padding: 20px;
        }

        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }
}
