.video--play-pause {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    margin: 0;
    padding: 0;
    width: 80px;
    height: 80px;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border: 5px solid $brand-primary;
    background: none;
    cursor: pointer;
    z-index: 5;

    &:before,
    &:after {
        position: absolute;
        display: block;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:before {
        margin-left: 2px;
        border-left: 22px solid $brand-primary;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
    }

    &.pause {
        $pause--border-width: 6px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility .3s;

        &:before,
        &:after {
            height: 30px;
            border: none;
            border-left: $pause--border-width solid $brand-primary;
        }

        &:before {
            margin-left: -$pause--border-width;
        }

        &:after {
            margin-left: $pause--border-width;
        }

        *:hover > & {
            opacity: 1;
            visibility: visible;
        }
    }
}