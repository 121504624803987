
// Basic Variables
$font-size-root: 18px;
$line-height-base: 1.38;
$wrapper-max-width: (1800px / $font-size-root) * 1rem;

// Basic Colors
$white:   #fff !default;
$gray:    #e0e0e0;
$black:   #000 !default;

$body-bg: $black;
$text-color: $white;
$brand-primary: #F39100;
$blog-bg: #E8E8E8;

// Typography
$font-family-base: 'Zilla Slab', serif;
$font-family-special: 'Barlow Condensed', Arial, sans-serif;
$font-size-base: $font-size-root;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 600;

$h1-font-size:                55px;
$h2-font-size:                40px;
$h3-font-size:                32px;
$h4-font-size:                25px;
$h5-font-size:                20px;
$h6-font-size:                $font-size-base !default;

$h1-min-font-size:            42px;
$h2-min-font-size:            35px;
$h3-min-font-size:            30px;
$h4-min-font-size:            25px;
$h5-min-font-size:            $h6-font-size;
$h6-min-font-size:            $h6-font-size;

$lead-font-size:			  25px;
$lead-min-font-size:		  20px;

$headings-margin-bottom: 1em;
$paragraph-margin-bottom: 1em;

// Fluid Typo Viewport
$viewport-width-min: 768px;
$viewport-width-max: 1200px;

// Containers
$container-padding: 60px;
$container-max-width: 1420px;
$container-max-width--narrow: 1140px;

// Grid
$grid-gutter-width: 30px;

// Links
$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 10%);
$link-decoration: none;
$link-hover-decoration: none;

// Tables
$table-padding-x: 15px;
$table-padding-y: 10px;
$table-border-color: rgba($white, .2);
$table-head-secondary-color: $white;
$table-head-secondary-bg: transparent;

// Buttons
$btn-font-family: $font-family-special;
$btn-font-size: 20px;
$btn-font-weight: $font-weight-bold;
$btn-line-height: 1.2;
$btn-padding: 0.35em 0.75em;
$btn-border-width: 2px;
$btn-border-radius: 5px;
$btn-disabled-opacity: 0.4;
$btn-primary-bg: $brand-primary;
$btn-special--font-size: 25px;

// Forms
$form-gutter-width: 15px;
$formfield-color: $black;
$formfield-bg: $white;
$formfield-padding: 7px 12px;
$formfield-border-width: 1px;
$formfield-border-radius: 5px;
$formfield-border-color: $black;
$formfield-focus-color: $black;
$formfield-focus-border-color: rgba($brand-primary, .5);
$formfield-focus-bg: $formfield-bg;

// Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px,
        outer: 1920px,
) !default;

$maxWidthDesktop: map-get($grid-breakpoints, xl);
$maxWidthDesktopPlus: map-get($grid-breakpoints, xxxl);
$outerLimit: map-get($grid-breakpoints, outer);