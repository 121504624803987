.blog {
    $blog-max-width: 1460px;

    &--list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @include fluid-property('grid-column-gap', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 50px);
        @include fluid-property('grid-row-gap', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 50px);
        margin-left: auto;
        margin-right: auto;
        padding: 0 $container-padding;
        max-width: $blog-max-width + ($container-padding * 2);

        @include media-breakpoint-between(sm, lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-only(sm) {
            grid-column-gap: $grid-gutter-width / 2;
            grid-row-gap: $grid-gutter-width / 2;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 40px;
            grid-template-columns: 1fr;
        }

        .teaser {
            $content-padding-x: 90px;
            $content-padding-x--small: 30px;

            position: relative;
            background: $brand-primary;
            color: $black;
            overflow: hidden;

            &--image {
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            &--text {
                @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 50px);
                @include fluid-property('padding-bottom', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 170px);
                @include fluid-property('padding-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $content-padding-x--small, $content-padding-x);
                @include fluid-property('padding-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $content-padding-x--small, $content-padding-x);

                h2, h3, h4 {
                    margin-bottom: 1.2em;
                    color: $white;

                    @include media-breakpoint-down(lg) {
                        margin-bottom: .8em;
                    }
                }

                p {
                    margin: 0;
                }
            }

            &--more {
                position: absolute;
                display: block;
                @include fluid-property('left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $content-padding-x--small, $content-padding-x);
                right: 0;
                @include fluid-property('bottom', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 50px);
                padding: to-em(10px, $btn-special--font-size) to-em(50px, $btn-special--font-size);
                color: $white;
                background: $black;
                text-decoration: none;
                font-family: $font-family-special;
                font-weight: $font-weight-bold;
                line-height: $btn-line-height;
                @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 20px, $btn-special--font-size);
                white-space: nowrap;
                transition: transform .3s;

                @include media-breakpoint-down(md) {
                    left: auto;
                    padding: 9px 30px 9px 40px;
                    font-size: 18px;
                }

                &:hover {
                    transform: translateX(to-em(-20px, $btn-special--font-size));
                }

                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    left: to-em(30px, $btn-special--font-size);
                    width: 5px;
                    height: to-em(28px, $btn-special--font-size);
                    @include updateIcon('arrow', '');
                    background-position: center center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    vertical-align: bottom;
                    will-change: transform;
                }

                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 100%;
                    margin-left: -1px;
                    bottom: 0;
                    background: $black;
                    width: 100%;
                }
            }
        }
    }

    &--categories {
        margin-left: auto;
        margin-right: auto;
        padding: 0 $container-padding;
        @include fluid-property('margin-bottom', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 50px, 90px);
        max-width: $blog-max-width + ($container-padding * 2);

        @include media-breakpoint-down(sm) {
            padding: 0 40px;
        }

        ul {
            @include reset-list;

            @include media-breakpoint-down(sm) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 15px;
                grid-row-gap: 15px;
            }

            li {
                display: inline-block;
                margin: 0 7px 10px 0;

                @include media-breakpoint-down(sm) {
                    margin: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    display: block;
                    padding: to-em(12px, 25px);
                    min-width: to-em(140px, 25px);
                    @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 20px, 25px);
                    line-height: 1;
                    font-family: $font-family-special;
                    font-weight: $font-weight-bold;
                    border: 1px solid $brand-primary;
                    border-radius: $btn-border-radius;
                    text-align: center;
                    transition: color .3s, background-color .3s;

                    @include media-breakpoint-down(sm) {
                        min-width: 0;
                    }

                    &.is-active {
                        background: $brand-primary;
                        color: $black;
                    }

                    &:hover:not(.is-active) {
                        color: $white;
                        background: rgba($brand-primary, .3);
                    }
                }
            }
        }
    }

    &--pagination {
        margin-left: auto;
        margin-right: auto;
        @include fluid-property('margin-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 50px, 90px);
        padding: 0 $container-padding;
        max-width: $blog-max-width + ($container-padding * 2);
    }

    &--detail {
        .area {
            .h1, .h2, .h3, .h4, .h5, .h6,
            h1, h2, h3, h4, h5, h6 {
                small {
                    color: $black;
                }
            }
        }

        .area--textmedia {
            &:not(.is-highlighted) {

                > *:not([class*="media-width--"]) {

                    .area--thumbnail {
                        padding-left: $container-padding;
                        padding-right: 0;

                        @include media-breakpoint-only(md) {
                            padding-left: 0;
                        }

                        @include media-breakpoint-down(md) {
                            padding-left: 0;
                            padding-bottom: $grid-gutter-width;
                        }

                        img {
                            max-width: 750px;
                        }
                    }

                    .area--textmedia--text {
                        padding: 0;
                        padding-left: 80px;
                        padding-right: $container-padding;

                        @include media-breakpoint-between(sm, lg) {
                            padding-left: $container-padding;
                        }

                        @include media-breakpoint-down(sm) {
                            padding-left: 40px;
                            padding-right: 40px;
                        }
                    }

                    .area--text--inner {
                        max-width: 490px;

                        @include media-breakpoint-down(md) {
                            max-width: none;
                        }
                    }

                    &.thumbnail--right {
                        .area--thumbnail {
                            padding-left: 0;
                            padding-right: $container-padding;

                            @include media-breakpoint-only(md) {
                                padding-right: 0;
                            }

                            @include media-breakpoint-down(md) {
                                padding-top: 30px;
                                padding-right: 0;
                            }
                        }

                        .area--textmedia--text {
                            padding-left: $container-padding;
                            padding-right: 80px;

                            @include media-breakpoint-between(sm, lg) {
                                padding-right: $container-padding;
                            }

                            @include media-breakpoint-down(sm) {
                                padding-left: 40px;
                                padding-right: 40px;
                            }
                        }
                    }
                }

                .icon {
                    transform: translateY(0);
                }
            }
        }
    }

    &--footer {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        @include fluid-property('margin-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 150px);
        padding: 0 $container-padding;
        max-width: $container-max-width--narrow + ($container-padding * 2);
        text-align: center;
        overflow-x: hidden;

        @include media-breakpoint-down(md) {
            margin-top: 50px;
            padding: 0 50px;
        }
    }

        &--share {
            $share-font-size: 25px;

            display: inline-block;
            @include fluid-property('margin-bottom', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 180px);
            padding: to-em(9px, $share-font-size) to-em(10px, $share-font-size);
            border: 1px solid $brand-primary;
            border-radius: $btn-border-radius;
            font-size: $share-font-size;
            line-height: 1;
            white-space: nowrap;

            @include media-breakpoint-down(md) {
                margin-bottom: 50px;
                font-size: 18px;
            }

            * {
                vertical-align: middle;
            }

            .icon {
                margin-right: 2px;
                width: to-em(30px, $share-font-size);
                height: to-em(30px, $share-font-size);

                @include media-breakpoint-down(md) {
                    width: 24px;
                    height: 24px;
                }

                &--share {
                    margin-right: 2px;

                    @include media-breakpoint-down(md) {
                        margin-right: 10px;
                    }
                }
            }



            a {
                display: inline-block;
                margin-right: to-em(15px, $share-font-size);

                @include media-breakpoint-down(md) {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }

                .icon {
                    display: block;
                }
            }

            span {
                display: inline-block;
                margin-right: to-em(15px, $share-font-size);
                font-family: $font-family-special;
                font-weight: $font-weight-bold;
                color: $brand-primary;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        &--back {
            text-align: right;

            a {
                position: relative;
                display: inline-block;
                padding-left: 55px;
                background: $black;
                color: $white;
                font-family: $font-family-special;
                font-weight: $font-weight-bold;
                font-size: 25px;
                line-height: 50px;
                white-space: nowrap;
                transition: transform .3s;

                @include media-breakpoint-down(md) {
                    padding-left: 40px;
                    line-height: 40px;
                    font-size: 18px;
                }

                &:hover {
                    transform: translateX(-30px);
                }

                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 35px;
                    top: 0;
                    bottom: 0;
                    width: 6px;
                    @include updateIcon(arrow, '');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;

                    @include media-breakpoint-down(md) {
                        left: 20px;
                    }
                }

                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 100%;
                    bottom: 0;
                    width: 100vw;
                    background: $black;
                }
            }
        }
}