.area--nav-teaser {
    &.is-narrow {

        @include media-breakpoint-down(lg) {
            padding-left: 0;
            padding-right: 0;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);

        @include media-breakpoint-down(md) {
            margin-left: -7px;
            margin-right: -7px;
        }
    }

}

.nav-teaser {
    flex: 1 1 25%;
    position: relative;
    padding: 0 ($grid-gutter-width / 2) $grid-gutter-width;
    max-width: 25%;
    height: auto;
    text-align: center;

    @include media-breakpoint-down(lg) {
        padding: 0 7px 14px;
    }

    @include media-breakpoint-down(md) {
        flex-basis: 50%;
        max-width: 50%;
    }

    &, a:not(.btn) {
        display: block;
        color: $black;
        text-decoration: none;
    }

    &:before {
        display: block;
        content: '';
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    &--inner {
        position: absolute;
        left: $grid-gutter-width / 2;
        top: 0;
        right: $grid-gutter-width / 2;
        bottom: $grid-gutter-width;
        padding: 10px;
        background: $brand-primary;
        overflow: hidden;
        transition: background-color .3s;

        @include media-breakpoint-down(lg) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            left: 7px;
            right: 7px;
            bottom: 14px;
        }

        &:hover {
            @include media-breakpoint-up(md) {
                background-color: $white;

                .icon {
                    width: 30px;
                    height: 30px;
                    filter: none;
                }

                .nav-teaser--text {
                    opacity: 1;
                    visibility: visible;
                    padding-top: 20px;

                    @include media-breakpoint-only(md) {
                        max-height: 250px;
                    }
                }
            }
        }
    }

        .icon {
            @include fluid-property('margin-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 20px, 50px);
            @include fluid-property('width', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 80px);
            @include fluid-property('height', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 80px);

            &.icon--group {
                @include fluid-property('width', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 71px, 95px);
            }

            filter: saturate(0) brightness(0) invert(100%) opacity(1);
            transition: filter .3s, width .3s, height .3s;

            @include media-breakpoint-down(lg) {
                margin-top: 0;
            }
        }

        &--title {
            margin-top: 1em;
            @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 20px, 25px);
            line-height: 1.2;
            font-family: $font-family-special;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            @include media-breakpoint-only(md) {
                margin-top: 15px;
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                margin-top: 10px;
                width: 100%;
                font-size: 18px;
            }

            p {
                margin: 0;
            }
        }

    &--text {
        font-family: $font-family-base;
        @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 16px, 18px);
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility .3s, max-height .3s, padding .3s;

        @include media-breakpoint-only(md) {
            max-height: 0;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        p {
            margin: 0;
        }
    }
}