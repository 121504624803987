$gn-brown: #AA8241;
$gn-brown-medium: #CCAE6E;
$gn-brown-light: #D7C08A;
$gn-red: #A53131;
$gn-orange: #E7A900;
$gn-orange-light: #FCEED4;

$sw-blue: #004f9f;
$sw-dark-blue: #013e7c;

.sw-microsite {
    color: $white;
    background-color: $sw-blue;

    .area {

        a {
            color: $white;
        }

        ul {

            li:before {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg) brightness(102%) contrast(102%);
            }
        }
    }

    .accordion-header {

        &:after {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg) brightness(102%) contrast(102%);
        }
    }

    .tab-nav {
        border-bottom: 1px solid $white;

        .tab-link {
            color: $white;

            &:hover {
                color: rgba($white, .6);
            }

            &.active {
                background-color: $sw-dark-blue;
                color: $white;
            }
        }
    }

    .btn {
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
        color: $white;

        small {
            color: $white;
        }
    }

    .page-header--gn {
        background-color: $white;
        padding: 0 25px;
        height: 100vh;
        display: flex;
        flex-direction: column;

        &.no-media {
            height: auto;

            @include media-breakpoint-up(lg) {
                margin-bottom: 150px;
            }
        }

        @include media-breakpoint-up(lg) {
            padding: 0 55px;

        }

        &-top {
            position: relative;
            padding-top: 15px;

            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 150px;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                padding: 0 55px;
                z-index: 9;
                background-color: $white;
            }
        }

        &-logos {

            & > span {
                position: fixed;
                top: 0;
                left: 0;
                height: 80px;
                width: 100vw;
                background-color: $white;
                z-index: 8;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            @include media-breakpoint-up(lg) {
                display: flex;
                flex: 1;
            }
        }

        &-media {
            flex: 1;

            @include media-breakpoint-up(lg) {
                margin-top: 150px;
            }

            .gn-media--wrapper {
                display: flex;
                height: 100%;
                padding: 25px 0;

                @include media-breakpoint-up(lg) {
                    padding: 0 0 50px 0;
                    gap: 50px;
                }

                .media-col {
                    position: relative;
                    flex: 1;

                    &:first-child, &:last-child {
                        display: none;

                        @include media-breakpoint-up(lg) {
                            display: block;
                        }
                    }

                    img, video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    video {
                        object-fit: cover;
                    }
                }
            }
        }

        .logo {
            max-width: 120px;
            position: fixed;
            top: 15px;
            left: 25px;
            z-index: 9;

            @include media-breakpoint-up(lg) {
                max-width: 185px;
                position: relative;
                top: unset;
                left: unset;
            }

            &-culture {
                width: 100%;
                margin-left: -25px;
                text-align: center;
                margin-top: 50px;

                @include media-breakpoint-up(lg) {
                    margin-left: 0;
                    margin-top: 0;
                    max-width: 518px;
                }

                img {
                    width: 100%;
                    height: auto;
                    max-width: 518px;
                }
            }
        }

    }

    .gn-nav {
        padding: 20px 0 10px 0;

        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 650px;
            width: 100%;
            flex: 1;
        }

        &--list {
            @include reset-list();
            display: flex;
            justify-content: center;

            li {
                padding: 0 20px;
                line-height: 1;
                text-align: center;
                display: flex;
                align-items: center;
                @include fluid-type($viewport-width-min, $viewport-width-max, 15px, 25px);

                @include media-breakpoint-up(lg) {
                    padding: 0 25px;
                }

                a {
                    color: $black;
                }

                &:not(:last-child) {
                    border-right: 1px dashed $black;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .lang-nav--gn {
            position: fixed;
            right: 25px;
            top: 32px;
            z-index: 9;

            @include media-breakpoint-up(lg) {
                margin-left: 20px;
                position: relative;
                top: unset;
                right: unset;
            }

            a {
                color: $black;
                line-height: 25px;
                @include fluid-type($viewport-width-min, $viewport-width-max, 20px, 25px);
            }
        }
    }

    .btn {

        &-primary {
            @include custom-button-variant($sw-dark-blue, $sw-dark-blue, $white, $black, $black, $white);
        }

        &-secondary {
            @include custom-button-variant($black, $black, $white, $sw-dark-blue, $sw-dark-blue, $white);
        }

        &-outline {
            @include custom-button-variant(transparent, $white, $white, $white, $white, $sw-blue);
        }

        &-special {
            background: $black;

            &:after {
                background: $black;
            }

            &:before {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
            }
        }
    }

    .area--header {

        &.is-special {

            h1,
            h2,
            h3 {
                background: $white;
                font-weight: 400;
                text-transform: initial;

                &:before {
                    background: $sw-dark-blue;
                }

                i {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
                }
            }

            .area--header--special-subline {
                color: $black;
                font-family: 'Souvenir Light BT', serif;
                font-weight: 400;
            }
        }
    }

    .area--text table {

        thead {
            tr:first-child th {
                background-color: $sw-dark-blue;
                color: $white;
                border-bottom: 8px solid transparent;
                font-weight: 400;
            }
        }

        td {
            background-color: $white;
            color: $black;
            border-bottom: 1px solid $sw-blue;
        }
    }

    .area--infobox {

        .infobox {
            border: 5px dashed $gn-red;

            &--title h1, h2, h3, h4, h5, h6 {
                color: $white;
            }

            &-primary {
                background-color: $gn-red;

                .infobox--title h1, h2, h3, h4, h5, h6 {
                    color: $white;
                }
            }
        }
    }

    .area--textmedia {

        &--text {
            background: $white;
            color: $black;

            h1, h2, h3, h4, h5, h6 {
                color: $black;
            }

            a:not(.btn) {
                color: $sw-dark-blue;
            }

            .icon {
                filter: brightness(0) saturate(100%);
            }
        }

        .teaser--inner {
            background: $white;

            p {
                text-transform: initial;
                font-weight: 400;
            }

            .icon {
                filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(4137%) hue-rotate(197deg) brightness(95%) contrast(99%);
            }
        }

        &.is-special {

            .thumbnail--right {
                .area--text--inner {
                    margin-right: 0;
                }
            }

            .thumbnail--left {
                .area--text--inner {
                    margin-left: 0;
                }
            }

            .area--textmedia--text {
                background-color: transparent;
            }
        }
    }

    .area--media {

        &--content {

            p {
                font-weight: 400;
            }

            &.orange-text p {
                color: $gn-orange-light;
            }

            .btn-special {
                background-color: $gn-red;
                color: $gn-orange-light;

                &:after {
                    background-color: $gn-red;
                }
            }
        }
    }

    .area--grid {

        .grid {

            & > [class*="grid-item--"] {
                background: $white;

                p {
                    text-transform: initial;
                    font-weight: 400;
                }

                .icon {
                    filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(4137%) hue-rotate(197deg) brightness(95%) contrast(99%);
                }

                .video--play-pause {
                    border: 5px solid $white;

                    &:before {
                        border-left: 22px solid $white;
                    }

                    &.pause {

                        &:before, &:after {
                            border-left: 6px solid $white;
                        }
                    }
                }

                &.has-media {

                    .grid-item--text {
                        color: $white;
                    }
                    .icon {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
                    }
                }
            }
        }
    }

    .area--nav-teaser {

        .nav-teaser--inner {
            background: $sw-dark-blue;

            p {
                font-weight: 400;
                text-transform: initial;
                color: $white;
            }

            &:hover {
                background: $white;

                p {
                    color: $black;
                }

                .icon {
                    filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(4137%) hue-rotate(197deg) brightness(95%) contrast(99%);
                }
            }
        }
    }

    .area--icon-teaser {

        .icon-teaser {

            .icon {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg) brightness(102%) contrast(102%);
            }

            &--text {
                color: $white;
            }

            &:after {
                border-right: 1px solid $white;
            }
        }
    }

    .blog--list {

        .teaser {
            background: $gn-brown-medium;

            &--text {

                h2, h3 {
                    color: $gn-brown;
                }
            }

            &--more {

                &:before {
                    filter: brightness(0) saturate(100%) brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(332deg) brightness(106%) contrast(104%);
                }
            }
        }
    }

    .area--separator {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg) brightness(102%) contrast(102%);
    }

    .area--section-header--icon {
        background-color: $white;

        i {
            filter: brightness(0) saturate(100%) invert(50%) sepia(57%) saturate(404%) hue-rotate(358deg) brightness(95%) contrast(91%);
        }

        h1, h2, h3 {
            text-transform: initial;
        }
    }

    .form-container {

        legend, label {
            color: $white;
        }

        input {

            &::file-selector-button, &::-webkit-file-upload-button {
                background-color: $sw-dark-blue;
                color: $white;
            }
        }
    }
}

.footer--gn {
    background-color: $white;

    &-main {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }

    &-box {
        flex: 1;
        padding: 40px;

        @include media-breakpoint-up(lg) {
            max-width: 780px;
            padding: 100px 100px 60px 100px;
        }

        .logo {
            width: 100%;
            max-width: 165px;
            margin: 0 auto 40px auto;

            @include media-breakpoint-up(lg) {
                max-width: 185px;
                margin: 0 0 60px 0;
            }
        }
    }

    &-address {
        text-align: center;
        padding-bottom: 45px;
        border-bottom: 1px solid rgba($black, .15);

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        p {
            margin-bottom: 0;
        }

        a {
            display: block;
            margin-top: 1rem;
            color: $black;
        }
    }

    &-links {
        display: flex;
        gap: 5px;
        padding: 25px 0;
        border-bottom: 1px solid rgba($black, .15);

        @include media-breakpoint-up(lg) {
            gap: 30px;
        }

        ul {
            flex: 1;
            @include reset-list();

            li {
                margin-bottom: 5px;
                position: relative;
                padding-left: 14px;

                &:before {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.33333 5.06752C5.33333 5.23819 5.264 5.40148 5.1407 5.51976L1.0624 9.44135C0.812518 9.68151 0.415339 9.67383 0.17518 9.4241C-0.064978 9.17437 -0.0572917 8.77719 0.192592 8.53703L3.80062 5.06752L0.192749 1.59816C-0.0569779 1.35816 -0.064978 0.960826 0.175337 0.711099C0.415653 0.461372 0.812675 0.453529 1.06256 0.693844L5.14086 4.61544C5.264 4.73356 5.33333 4.89685 5.33333 5.06752Z' fill='black'/%3E%3C/svg%3E%0A");
                    width: 6px;
                    height: 10px;
                    position: absolute;
                    top: 6px;
                    left: 0;

                }
            }

            a {
                color: $black;
            }
        }
    }

    &-social {
        padding-top: 45px;

        ul {
            @include reset-list();
            display: flex;
            justify-content: center;

            @include media-breakpoint-up(lg) {
                justify-content: start;
            }

            li {
                padding: 0 20px;

                &:not(:last-child) {
                    border-right: 1px dashed $black;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        i {
            font-size: 30px;
        }
    }

    &-map {
        flex: 1;
        min-height: 597px;

        .location-box {
            max-width: unset;
            padding: 0;
            height: 100%;
        }

        .map-container {
            margin-right: 0 !important;
            height: 100%;
            width: 100%;
            flex: 1;
            max-width: unset;

            @include media-breakpoint-down(lg) {
                padding-bottom: 597px !important;
                flex: 0 0 100% !important;
            }
        }
    }
}