.footer {
    position: relative;
    @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 100px);
    padding-bottom: 35px;
    font-family: $font-family-special;

    @include media-breakpoint-down(md) {
        padding-top: 50px;
        padding-bottom: 25px;
    }

    .logo {
        @include fluid-property('width', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 165px, 200px);
        filter: saturate(0) brightness(0) invert(100%) opacity(1);

        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }

    &--main {
        display: flex;
        flex-wrap: wrap;

        h4 {
            white-space: nowrap;
            margin-bottom: 25px;
            color: $white;

            .icon {
                vertical-align: bottom;
                margin-right: 15px;

                @include media-breakpoint-down(md) {
                    vertical-align: baseline;
                    margin-right: 10px;
                }

                &--map-pin-bold {
                    width: 22px;
                    height: 30px;

                    @include media-breakpoint-down(md) {
                        width: 13px;
                        height: 18px;
                    }
                }

                &--envelope-bold,
                &--jobs {
                    width: 36px;
                    height: 25px;

                    @include media-breakpoint-down(md) {
                        width: 20px;
                        height: 14px;
                    }
                }
            }

            a {
                transition: color .2s;

                &:hover {
                    color: $brand-primary;
                }
            }
        }

        p {
            margin-bottom: 0;
        }

        address {
            margin-bottom: 0;
        }
    }

        &--col {
            flex: 1 1 auto;

            + .footer--col {
                @include media-breakpoint-up(md) {
                    margin-left: 30px;
                }
            }

            @include media-breakpoint-only(md) {
                flex: 1 1 33%;
                order: 1;
            }

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                margin-bottom: 30px;
                order: 1;
            }

            &:nth-child(3) {
                @include media-breakpoint-only(md) {
                    flex: 0 0;
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }
            }

            &:nth-child(4),
            &:nth-child(5) {
                @include media-breakpoint-down(lg) {
                    order: 2;
                }
            }

            &:nth-child(5) {
                @include media-breakpoint-up(md) {
                    max-width: 270px;
                }
            }
        }

        &--links {
            margin-bottom: 20px;

            ul {
                @include reset-list;

                li {
                    position: relative;
                    padding-left: 15px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 10px;
                    }

                    &:last-child {
                        @include media-breakpoint-down(md) {
                            margin-bottom: 0;
                        }
                    }

                    &:before {
                        position: absolute;
                        content: '';
                        display: block;
                        left: 0;
                        top: 0;
                        width: 6px;
                        height: 1.4em;
                        @include updateIcon('arrow', '');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: left center;
                    }

                    a {
                        color: $white;
                        transition: color .3s;

                        &:hover {
                            color: $brand-primary;
                        }
                    }
                }
            }
        }

        &--social {
            ul {
                @include reset-list;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;

                @include media-breakpoint-down(md) {
                    justify-content: space-around;
                }

                li {
                    a {
                        display: block;
                        font-size: 30px;
                        line-height: 1;

                        .icon {
                            display: block;
                            filter: saturate(0) brightness(0) invert(100%) opacity(1);
                        }
                    }
                }
            }
        }

        &--logos {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 50px, 80px);

            @include media-breakpoint-down(md) {
                justify-content: space-evenly;
            }

            a {
                display: block;
                margin-right: 20px;
                width: auto;
                @include fluid-property('height', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 87px);

                @include media-breakpoint-down(md) {
                    margin-right: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:nth-child(2) img {
                    filter: invert(100%);
                }

                img {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
        }

    &--newsletter {
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);
        flex: 0 0 auto;

        @include media-breakpoint-between(lg, xl) {
            margin-top: 30px;
        }

        @include media-breakpoint-only(md) {
            margin: 30px 0 40px;
            order: 1;
        }

        @include media-breakpoint-down(md) {
            margin: 15px 0 40px;
            order: 1;
        }

        &--container {
            padding: 15px 20px 15px 0;
            @include fluid-property('padding-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 160px, 250px);
            display: inline-block;
            background: $brand-primary;

            @include media-breakpoint-only(md) {
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                display: block;
                padding: 15px 60px 20px;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 40px;
                padding-right: 40px;
            }
        }

            &--text {
                position: relative;
                color: $black;
                margin-bottom: 12px;
                max-width: 452px;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;

                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                }

                span {
                    display: block;
                    flex: 1 0;
                    font-family: $font-family-base;

                    &:not(.title) {
                        @include media-breakpoint-down(md) {
                            display: block;
                            width: 100%;
                            flex: 0 0 100%;
                        }
                    }


                    &.title {
                        flex: 0 0;
                        padding-right: 18px;
                        font-size: 40px;
                        font-family: $font-family-special;
                        text-transform: uppercase;
                        font-weight: $font-weight-bold;

                        @include media-breakpoint-down(md) {
                            padding: 0;
                            font-size: 35px;
                            line-height: 55px;
                        }
                    }
                }
            }

            form {
                @include media-breakpoint-only(md) {
                    max-width: 452px;
                }
            }

                .icon {
                    position: absolute;
                    top: 8px;
                    right: 100%;
                    margin-right: 20px;
                    width: 80px;
                    height: 55px;

                    @include media-breakpoint-down(md) {
                        position: relative;
                        top: auto;
                        right: auto;
                        width: 57px;
                        height: 40px;
                    }
                }

            .form-group {
                display: flex;
                flex-wrap: nowrap;
                margin: 0;

                input[type="text"] {
                    margin-right: 10px;

                    &:focus {
                        box-shadow: 0 0 0 0.25rem rgba($black, .15);
                    }
                }

                button[type="submit"] {
                    flex: 0 0;
                    padding: .3em 1.2em;
                    border-width: 2px;
                    border-color: $black;

                    @include media-breakpoint-down(md) {
                        padding: .3em 15px;
                    }

                    &:hover {
                        color: $black;
                        border-color: $black;
                    }
                }
            }
    }

    &--bottom {
        display: flex;
        justify-content: flex-end;
        @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 20px, 50px);

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        .conecto-copyright {
            $conecto-width: 140px;
            $conecto-height: 8px;
            width: $conecto-width;
            height: auto;
            overflow: hidden;
            background-image: svg-load('layout/svg/site_by_CONECTO.svg', fill='');
            background-postion: left top;
            background-repeat: no-repeat;
            background-size: contain;
            filter: saturate(0) brightness(0) invert(100%) opacity(1);
            text-indent: -9999em;
            font-size: 0;

            @include media-breakpoint-down(md) {
                //width: 65px;
            }

            &:after {
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: ((strip-units($conecto-height) * 100) / strip-units($conecto-width)) + 0%;
            }
        }
    }
}