.area--youtube {
    .tac_float {
        font-size: 20px;
    }

    .tac_activate {
        .tac_float {
            .tarteaucitronAllow {
                display: block;
                margin: 30px auto 0;
            }
        }
    }
}