.app-banner {
    $app-banner-text-size: 55px;

    display: none;
    flex-wrap: nowrap;
    position: fixed;
    left: 0;
    bottom: to-em(150px, $app-banner-text-size);
    width: to-em(750px, $app-banner-text-size);
    max-width: 100vw;
    @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 40px, $app-banner-text-size);
    color: $black;
    transform: translateX(-100%);
    transition: transform .35s ease-out;
    z-index: 999;

    @include media-breakpoint-down(md) {
        bottom: 50px;
        font-size: 25px;
    }

    &.display {
        display: flex;
    }

    &.is-active {
        transform: translateX(0);
    }

    &--close {
        position: absolute;
        top: 25px;
        right: 25px;
        background: transparent;

        @include media-breakpoint-down(md) {
            top: 15px;
            right: 15px;
        }
    }

    &--icon {
        position: relative;
        flex: 0 0 auto;
        width: to-em(180px, $app-banner-text-size);
        height: to-em(180px, $app-banner-text-size);
        background: $black;

        @include media-breakpoint-down(md) {
            width: 100px;
            height: 100px;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: to-em((180px / 2), $app-banner-text-size);
            width: to-em(80px, $app-banner-text-size);
            height: to-em(80px, $app-banner-text-size);
            transform: translate(-50%, -50%);

            @include media-breakpoint-down(md) {
                left: 50px;
                width: 60px;
                height: 60px;
            }
        }
    }

    &--content {
        padding: 30px;
        background: $brand-primary;

        @include media-breakpoint-down(md) {
            padding: 20px;
            max-width: calc(100vw - 100px);
        }

        header {
            font-family: $font-family-special;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            line-height: 1;

            p {
                margin: 0;
            }
        }

        > p {
            font-size: $font-size-base;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

        &--links {
            @include media-breakpoint-down(md) {
                display: flex;
                flex-wrap: nowrap;
                margin: 20px 0 0 -5px;
                align-items: center;
            }

            a {
                display: inline-block;

                @include media-breakpoint-down(md) {
                    flex: 0 1 50%;
                }

                img {
                    @include media-breakpoint-down(md) {
                        display: block;
                        width: 100%;
                        height: auto;

                    }
                }
            }
        }
}