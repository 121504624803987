h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.2;
    font-family: $font-family-special;
    font-weight: $font-weight-bold;
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-all;
    color: $brand-primary;

    @include hyphens-auto(true);

    a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }

    small {
        display: block;
        margin-top: 0.25em;
        line-height: 1.15;
        font-size: .6em;
        font-weight: $font-weight-normal;
        word-break: normal;
        color: $white;
    }

    .is-highlighted:not(.white) &,
    .infobox-primary & {
        color: $white;
    }
}


h1, .h1 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h1-min-font-size, $h1-font-size);
}


h2, .h2 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h2-min-font-size, $h2-font-size);
}


h3, .h3 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h3-min-font-size, $h3-font-size);

    small {
        @include media-breakpoint-down(md) {
            font-size: $font-size-base;
        }
    }
}


h4, .h4 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h4-min-font-size, $h4-font-size);

    small {
        font-size: .8em;

        @include media-breakpoint-down(md) {
            font-size: $font-size-base;
        }
    }
}


h5, .h5 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h5-min-font-size, $h5-font-size);

    small {
        font-size: 1em;
    }
}


h6, .h6 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h6-min-font-size, $h6-font-size);

    small {
        font-size: 1em;
    }
}


hr {
    color: $black;
    margin-top: to-em(30px);
    margin-bottom: to-em(30px);
    opacity: 1;

    .main-content & {
        @include fluid-property('height', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 6px, 8px);
        border: none;
        background-image: svg-load('layout/svg/spacer.svg', fill='');
        background-repeat: repeat-x;
        background-position: center top;
        background-size: auto 100%;

        @include media-breakpoint-down(md) {
            background-image: svg-load('layout/svg/spacer-mobile.svg', fill='');
        }
    }
}

p {
    margin-bottom: $paragraph-margin-bottom;
}

.lead {
    font-family: $font-family-special;
    @include fluid-type($viewport-width-min, $viewport-width-max, $lead-min-font-size, $lead-font-size);
    margin-bottom: $paragraph-margin-bottom * 1.2;
}

// text alignment
.text {
    &-left {
        text-align: left !important;
    }

    &-right {
        text-align: right !important;
    }

    &-center {
        text-align: center !important;
    }

    &-justify {
        text-align: justify !important;
    }
}