.area--media {
    position: relative;

    .area--thumbnail {
        margin: 0;
    }

    &.area--media--multiple {
        .area--thumbnail {
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &--content {
        position: absolute;
        top: 0;
        left: 50%;
        padding: 0 $container-padding;
        @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 195px);
        @include fluid-property('padding-bottom', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 195px);
        width: 100%;
        max-width: $container-max-width--narrow + ($container-padding * 2);
        transform: translateX(-50%);

        @include media-breakpoint-down(md) {
            padding: 50px 40px 0;
        }

        &.white-text {
            p {
                color: $white;
            }
        }

        p {
            margin-bottom: to-em(55px, 80px);
            @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 60px, 80px);
            line-height: 1;
            text-transform: uppercase;
            font-family: $font-family-special;
            font-weight: $font-weight-bold;
            color: $black;

            @include media-breakpoint-down(md) {
                font-size: 35px;
                line-height: 1.15;
                text-transform: none;
            }
        }

        .btn-special {
            margin: 0;
            padding-left: 0;
            padding-right: to-em(65px, $btn-special--font-size);
            width: auto;

            &:hover {
                margin-left: to-em(60px, $btn-special--font-size);
            }

            &:after {
                margin-left: 0;
                margin-right: -1px;
                left: auto;
                right: 100%;
            }
        }

        // Individuelle Positionierung
        &.pos {
            &--bottom-left {
                top: auto;
                bottom: 0;
            }

            &--center-left {
                top: 50%;
                bottom: auto;
                transform: translate(-50%, -50%);
            }

            &--top-left {
                top: 0;
                bottom: auto;
            }

            &--bottom-right {
                top: auto;
                bottom: 0;
                text-align: right;
            }

            &--center-right {
                top: 50%;
                bottom: auto;
                transform: translate(-50%, -50%);
                text-align: right;
            }

            &--top-right {
                top: 0;
                bottom: auto;
                text-align: right;
            }

            &--bottom-center {
                top: auto;
                bottom: 0;
                transform: translateX(-50%);
                text-align: center;
            }

            &--top-center {
                top: 0;
                bottom: auto;
                transform: translateX(-50%);
                text-align: center;
            }

            &--center {
                top: 50%;
                bottom: auto;
                transform: translate(-50%, -50%);
                text-align: center;
            }

            // Button Special
            &--top-right,
            &--center-right,
            &--bottom-right {
                .btn-special {
                    margin-left: 0;
                    padding-left: to-em(65px, $btn-special--font-size);
                    padding-right: 0;

                    &:hover {
                        margin-right: to-em(60px, $btn-special--font-size);
                    }

                    &:after {
                        margin-right: 0;
                        margin-left: -1px;
                        left: 100%;
                        right: auto;
                    }
                }
            }
        }
    }
}