.embed-responsive {
    position: relative;
    width: 100%;
    height: 0;

    &-1by1 {
        padding-bottom: 100%;
    }

    &-3by2 {
        padding-bottom: 66.66666%;
    }

    &-16by9 {
        padding-bottom: 56.25%;
    }

    &-100vh {
        padding-bottom: calc(100vh - #{$header-top-height});

        @include media-breakpoint-between(md, lg) {
            padding-bottom: calc(100vh - #{$header-top-height--tablet});
        }

        @include media-breakpoint-down(md) {
            padding-bottom: calc(100vh - #{$header-top-height--mobile});
        }
    }

    > * {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    > iframe {
        border: none;
    }
}