.header-media {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > img,
    > picture > img,
    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    > img,
    > picture > img {
        object-fit: cover;
    }

    // Spezial für Video, da auf Smartphones das originale Seitenverhältnis dargestellt werden soll
    > video {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        height: auto;

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
        }
    }

    $header-text-size: 55px;
    $header-text-size--mobile: 25px;

    &--content {
        display: flex;
        position: absolute;
        bottom: to-em(150px, $header-text-size);
        left: 0;
        padding-left: to-em(180px, $header-text-size);
        width: to-em(750px, $header-text-size);
        height: auto;
        min-height: to-em(180px, $header-text-size);
        background: $brand-primary;
        overflow: hidden;
        font-family: $font-family-special;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        line-height: 1;
        align-items: center;
        @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 40px, $header-text-size);
        transition: transform .3s;

        @include media-breakpoint-down(md) {
            font-size: $header-text-size--mobile;
            line-height: 1.2;
            bottom: to-em(50px, $header-text-size--mobile);
            padding-left: to-em(100px, $header-text-size--mobile);
            width: to-em(300px, $header-text-size--mobile);
            min-height: to-em(100px, $header-text-size--mobile);
        }

        .has-video & {
            @include media-breakpoint-down(sm) {
                position: relative;
                left: auto;
                bottom: auto;
                margin-top: 30px;
            }
        }

        &,
        &:hover {
            color: $black;
        }

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            bottom: 0;
            width: to-em(180px, $header-text-size);
            background: $black;

            @include media-breakpoint-down(md) {
                width: to-em(100px, $header-text-size--mobile);
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: to-em((180px / 2), $header-text-size);
            width: to-em(80px, $header-text-size);
            height: to-em(80px, $header-text-size);
            transform: translate(-50%, -50%);

            @include media-breakpoint-down(md) {
                left: to-em((100px / 2), $header-text-size--mobile);
                width: to-em(60px, $header-text-size--mobile);
                height: to-em(60px, $header-text-size--mobile);
            }
        }

        h1 {
            font-size: 1em;
            color: $black;
            line-height: inherit;
        }

        p, h1 {
            margin: 0;
            padding: 5px 30px;

            @include media-breakpoint-down(md) {
                padding: 5px 20px;
            }
        }

        // Individuelle Positionierung
        &.pos {
            &--bottom-left {
                left: 0;
                top: auto;
                right: auto;
                bottom: to-em(150px, $header-text-size);

                @include media-breakpoint-down(md) {
                    bottom: to-em(50px, $header-text-size--mobile);
                }
            }

            &--center-left {
                left: 0;
                top: 50%;
                right: auto;
                bottom: auto;
                transform: translateY(-50%);
            }

            &--top-left {
                left: 0;
                top: to-em(150px, $header-text-size);
                right: auto;
                bottom: auto;

                @include media-breakpoint-down(md) {
                    top: to-em(50px, $header-text-size--mobile);
                }
            }

            &--bottom-right {
                left: auto;
                top: auto;
                right: 0;
                bottom: to-em(150px, $header-text-size);

                @include media-breakpoint-down(md) {
                    bottom: to-em(50px, $header-text-size--mobile);
                }
            }

            &--center-right {
                left: auto;
                top: 50%;
                right: 0;
                bottom: auto;
                transform: translateY(-50%);
            }

            &--top-right {
                left: auto;
                top: to-em(150px, $header-text-size);
                right: 0;
                bottom: auto;

                @include media-breakpoint-down(md) {
                    top: to-em(50px, $header-text-size--mobile);
                }
            }

            &--bottom-center {
                left: 50%;
                top: auto;
                right: auto;
                bottom: to-em(150px, $header-text-size);
                transform: translateX(-50%);

                @include media-breakpoint-down(md) {
                    bottom: to-em(50px, $header-text-size--mobile);
                }
            }

            &--top-center {
                left: 50%;
                top: to-em(150px, $header-text-size);
                right: auto;
                bottom: auto;
                transform: translateX(-50%);

                @include media-breakpoint-down(md) {
                    top: to-em(50px, $header-text-size--mobile);
                }
            }

            &--center {
                left: 50%;
                top: 50%;
                right: auto;
                bottom: auto;
                transform: translate(-50%, -50%);
            }
        }
    }

    // Spezial Header mit mehr Text und Icon Teaser
    &.is-special {

        @include media-breakpoint-only(md) {
            padding-top: calc(58vh - #{$header-top-height--tablet});
        }

        @include media-breakpoint-down(md) {
            padding-top: calc(58vh - #{$header-top-height--mobile});
        }

        > img,
        > picture > img,
        > video,
        > iframe {
            @include media-breakpoint-up(lg) {
                left: auto;
                right: 0;
                width: calc(100% - 840px);
                min-width: 55%;
            }

            @include media-breakpoint-only(md) {
                height: calc(58vh - #{$header-top-height--tablet});
            }

            @include media-breakpoint-down(md) {
                height: calc(58vh - #{$header-top-height--mobile});
            }
        }

        .header-media--content {
            bottom: auto;
            width: auto;

            @include media-breakpoint-up(lg) {
                position: relative;
                left: auto;
                top: auto;
                right: auto;
                flex: 0 0 auto;
                align-self: flex-start;
                min-width: calc(100% + #{$container-padding});
                max-width: calc(100vw - #{$container-padding});
                max-width: calc(100vw - #{$container-padding} - var(--scrollbarWidth));
            }

            @include media-breakpoint-only(md) {
                top: calc(58vh - #{$header-top-height--tablet});
                transform: translateY(calc(-100% + 65px));

            }

            @include media-breakpoint-down(lg) {
                min-width: 0;
                max-width: calc(100vw - 20px);
                max-width: calc(100vw - 20px - var(--scrollbarWidth));
            }

            @include media-breakpoint-down(md) {
                top: calc(58vh - #{$header-top-height--mobile});
                transform: translateY(calc(-100% + 50px));
            }
        }
    }

    &--special {
        @include media-breakpoint-up(lg) {
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            align-content: stretch;
            left: 0;
            top: 0;
            bottom: 0;
            padding-top: 10vh;
            width: 45%;
            max-width: 840px;
            min-width: 650px;
            background: $black;
        }

        @include media-breakpoint-down(md) {
            position: static;
        }
    }

        &--content-special {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 20px $container-padding 0;

            @include media-breakpoint-only(md) {
                padding-top: 115px;
            }

            @include media-breakpoint-down(md) {
                padding-top: 80px;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 40px;
                padding-right: 40px;
            }

            > * {
                width: 100%;

                @include media-breakpoint-up(lg) {
                    max-width: 540px;
                }
            }

            > p {
                margin: 0 0 20px 0;
            }
        }

        &--teaser {
            display: flex;
            align-self: flex-end;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
                justify-content: center;
            }
        }

            .icon-teaser {
                flex: 0 0 33.3333%;
                padding-top: 3vh;
                padding-bottom: 3vh;
                max-width: none;
                min-width: 0;
                font-size: 25px;
                font-family: $font-family-special;
                font-weight: $font-weight-bold;

                @include media-breakpoint-only(sm) {
                    flex-basis: 50%;
                }

                @include media-breakpoint-down(md) {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: 18px;
                }

                @include media-breakpoint-down(sm) {
                    flex-basis: 100%;
                }

                &:last-child {
                    @include media-breakpoint-down(md) {
                        padding-bottom: 0;
                    }
                }

                &:after {
                    top: 0;
                    bottom: 0;

                    @include media-breakpoint-only(sm) {
                        top: 20px;
                        bottom: 20px;
                    }

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                .icon {
                    margin-bottom: 20px;
                    width: 80px;
                    height: 80px;
                    max-height: 8vh;

                    @include media-breakpoint-down(md) {
                        width: 60px;
                        height: 60px;
                        max-height: none;
                    }
                }
            }
}

.scroll-direction--scrolled {

    .header-media--content {
        &:not(.pos--bottom-right) {
            transform: translateX(-100%);
        }

        &.pos--bottom-right {
            transform: translateX(100%);
        }
    }
}