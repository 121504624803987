.salzwelten-tours {
    padding: 0 $container-padding;

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    &--locations {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include fluid-property('margin-bottom', map-get($grid-breakpoints, md), map-get($grid-breakpoints, xl), 80px, 150px);
        margin-left: -$grid-gutter-width / 2;
        margin-right: -$grid-gutter-width / 2;

        @include media-breakpoint-down(md) {
            margin-left: -$grid-gutter-width / 4;
            margin-right: -$grid-gutter-width / 4;
        }

        @include media-breakpoint-down(sm) {
            margin-left: -40px - ($grid-gutter-width / 4);
            margin-right: -40px - ($grid-gutter-width / 4);
        }
    }

        &--location {
            position: relative;
            display: block;
            flex: 0 0 33.3333%;
            margin: 0;
            padding: 0 $grid-gutter-width / 2;
            max-width: 260px + $grid-gutter-width;
            border: none;
            background: none;
            text-align: center;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                flex-basis: 50%;
                margin-bottom: $grid-gutter-width / 2;
                padding: 0 $grid-gutter-width / 4;
            }

            &:after {
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 100%;
            }

            &--inner {
                position: absolute;
                left: $grid-gutter-width / 2;
                top: 0;
                right: $grid-gutter-width / 2;
                bottom: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: center;
                align-items: center;
                background: $brand-primary;
                color: $black;
                font-size: 25px;
                font-family: $font-family-special;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                transition: background-color .3s;

                @include media-breakpoint-down(md) {
                    left: $grid-gutter-width / 4;
                    right: $grid-gutter-width / 4;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 18px;
                }
            }

            &:not(.is-active) {
                .salzwelten-tours--location--inner {
                    &:hover {
                        background: rgba($brand-primary, .8);
                    }
                }
            }

            &.is-active {
                .icon {
                    filter: none;
                }

                .salzwelten-tours--location--inner {
                    background: $white;
                }
            }

            .icon {
                display: block;
                width: to-em(80px, 25px);
                height: to-em(80px, 25px);
                filter: saturate(0) brightness(0) invert(100%) opacity(1);
                transition: filter .3s;
            }

            span {
                display: block;
                margin-top: to-em(30px, 25px);
                width: 100%;
                line-height: 1;
            }
        }

    &--form {
        margin-left: auto;
        margin-right: auto;
        max-width: 1560px;

        @include media-breakpoint-down(md) {
            padding-left: 40px;
            padding-right: 40px;
        }

        &--grid {
            $col-gap: 50px;
            $col-gap--lg: 30px;
            display: grid;
            grid-template-columns: repeat(4, calc(25% - ((#{$col-gap} * 3) / 4)));
            grid-column-gap: $col-gap;
            grid-row-gap: $col-gap;

            @include media-breakpoint-only(lg) {
                grid-template-columns: repeat(4, calc(25% - ((#{$col-gap--lg} * 3) / 4)));
                grid-column-gap: $col-gap--lg;
                grid-row-gap: $col-gap--lg;
            }

            @include media-breakpoint-between(sm,lg) {
                grid-template-columns: repeat(2, calc(50% - (#{$col-gap--lg} / 2)));
            }

            @include media-breakpoint-down(md) {
                grid-column-gap: 40px;
                grid-row-gap: 40px;
            }

            @include media-breakpoint-down(sm) {
                grid-template-columns: 100%;
            }
        }

        .form-group {
            position: relative;
            width: 100%;
            margin-bottom: 0;

            &:first-child {
                @include media-breakpoint-between(sm,lg) {
                    grid-column: 1 / span 2;
                }
            }

            &:last-child {
                @include media-breakpoint-between(sm,lg) {
                    grid-column: 1 / span 2;
                }
            }
        }

        label {
            display: block;
            margin-bottom: 5px;
            color: $brand-primary;
            font-family: $font-family-special;
            font-weight: $font-weight-bold;
            font-size: 25px;
        }

        .numberpicker {
            button {
                border: none;
                background: none;
                padding: 0;
                height: 100%;
                width: 40px;
                color: $brand-primary;
                cursor: pointer;
                font-size: 34px;
                font-family: $font-family-special;
                transition: color .3s;

                > span {
                    display: inline-block;
                    pointer-events: none;
                }

                &:hover {
                    color: $black;
                }

                &:active {
                    > span {
                        transform: scale(.9);
                    }
                }
            }
        }

        input[type="text"],
        .btn-primary,
        .salzwelten-tours--nav {
            width: 100%;
            height: 70px;
            font-size: 25px;
        }

        .btn-primary {
            line-height: 1;
        }
        input[type="text"] {
            background: $white;
            border: none;
            border-radius: 5px;
            text-align: center;
            font-family: $font-family-special;
            font-weight: $font-weight-bold;
        }
    }

        &--nav {
            position: relative;
            overflow: hidden;
            color: $black;
            background: $white;
            border: none;
            border-radius: 5px;
            text-align: center;
            font-family: $font-family-special;
            font-weight: $font-weight-bold;

            &--inner {
                display: flex;
                flex-wrap: nowrap;
                transition: transform .3s;
            }

                &--item {
                    display: block;
                    flex: 0 0 100%;
                    width: 100%;
                    height: 100%;
                    line-height: 70px;
                }

            button {
                position: absolute;
                top: 50%;
                padding: 0;
                width: 40px;
                height: 100%;
                transform: translateY(-50%);
                transform-origin: 50% 50%;
                cursor: pointer;
                border: none;
                background: none;
                z-index: 2;

                &:hover {
                    .icon {
                        filter: saturate(0) brightness(0) invert(0) opacity(1);
                    }
                }

                &:active {
                    transform: scale(.95) translateY(-50%);
                }

                &.prev {
                    left: 0;

                    .icon {
                        transform: rotateZ(180deg);
                    }
                }

                &.next {
                    right: 0;
                }

                .icon {
                    font-size: 14px;
                    transition: filter .3s;
                }
            }

            .icon--calendar-bold {
                position: absolute;
                right: 38px;
                top: 50%;
                font-size: 28px;
                transform: translateY(-50%);
                pointer-events: none;
            }
        }

        input[type="text"].salzwelten-tours--date-field {
            position: absolute;
            left: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;

            &, &:hover, &:focus {
                outline: none;
                box-shadow: none;
            }

            &.has-changed {
                opacity: 1;
            }
        }

    &--groups {
        padding: 50px 30px;
        background: $white;
        color: $black;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            padding: 40px;
        }

        @media (max-width: 350px) {
            padding: 20px;
        }

        &--wrap {
            overflow: hidden;
        }

        &--inner {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            transition: transform .3s;
        }
    }

        &--group {
            position: relative;
            flex: 0 0 33.3333%;
            padding: 0 20px;
            height: 1px;
            visibility: hidden;

            @include media-breakpoint-between(md,xl) {
                flex-basis: 50%;
            }

            @include media-breakpoint-down(md) {
                flex-basis: 100%;
                padding: 0;
            }

            &.is-active {
                height: auto;
                visibility: visible;
            }

            &--title {
                margin-bottom: 50px;
                padding: 8px 0;
                background: $brand-primary;
                font-family: $font-family-special;
                font-size: 25px;
                text-align: center;
                font-weight: $font-weight-bold;
                white-space: nowrap;
                overflow: hidden;
            }
        }

            &--tour {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 20px 0;
                border-bottom: 1px solid rgba(0, 0, 0, .2);
                font-family: $font-family-special;
                font-weight: $font-weight-bold;
                @include fluid-type(map-get($grid-breakpoints, xl), map-get($grid-breakpoints, xxxl), 20px, 25px);

                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                }

                + .salzwelten-tours--tour {
                    padding: 20px 0;
                }

                &:last-child {
                    padding-bottom: 0;
                    border-bottom: none;
                }

                .btn-primary {
                    padding: to-em(8px, 25px) to-em(5px, 25px);
                    white-space: nowrap;
                    @include fluid-type(map-get($grid-breakpoints, xl), map-get($grid-breakpoints, xxxl), 20px, 25px);

                    @include media-breakpoint-down(lg) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 0;
                        width: 35px;
                        height: 35px;
                        line-height: 31px;
                        text-align: center;
                    }

                    &:hover {
                        background: transparent;
                        color: $black;
                        border-color: $black;

                        .icon {
                            filter: saturate(0) brightness(0) invert(0) opacity(1);
                        }
                    }

                    .icon {
                        margin-right: 0;

                        @include media-breakpoint-down(lg) {
                            font-size: 22px;
                            vertical-align: middle;
                        }
                    }

                    span {
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }

                &--time {
                    display: block;
                    flex: 0 0 auto;
                    width: to-em(70px, 25px);
                    height: to-em(70px, 25px);
                    line-height: calc(#{to-em(70px, 25px)} - 2px);
                    border: 1px solid $brand-primary;
                    border-radius: 5px;
                    text-align: center;

                    @include media-breakpoint-down(xl) {
                        width: 60px;
                        height: 60px;
                        line-height: 58px;
                    }

                }

                &--content {
                    padding: 0 15px;

                    strong {
                        display: block;
                        line-height: .9;

                        span {
                            display: block;
                            text-transform: uppercase;
                            line-height: 1;
                        }
                    }

                    .open-slots {
                        display: block;
                        font-family: $font-family-base;
                        font-weight: $font-weight-normal;
                        font-size: 18px;

                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                        }
                    }
                }
            }
}