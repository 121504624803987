table.contenttable,
.area--text table {
    width: 100%;

    &,
    tr {
        border: none;
    }

    th,
    td {
        padding: $table-padding-y $table-padding-x;

        &:first-child {
            @include media-breakpoint-up(md) {
                padding-left: $table-padding-x * 2;
            }
        }
        &:last-child {
            @include media-breakpoint-up(md) {
                padding-right: $table-padding-x * 2;
            }
        }

        min-width: 0;

        @include media-breakpoint-down(md) {
            min-width: 25vw;
        }

        @include media-breakpoint-down(sm) {
            min-width: 33vw;
        }

        @media screen and (max-width: 376px) {
            min-width: 50vw;
        }
    }

    thead {
        tr:first-child {
            th {
                background-color: $brand-primary;
                color: $black;
                text-transform: uppercase;
                border-bottom: 15px solid $black;
                font-family: $font-family-special;
                font-weight: $font-weight-bold;
                font-size: 25px;
                line-height: 1.2;

                .is-highlighted:not(.white) & {
                    background-color: $black;
                    color: $white;
                    border-bottom-color: $brand-primary;
                }

                .is-highlighted.white & {
                    border-bottom-color: $white;
                }
            }
        }
    }

    thead tr:not(:first-child) th,
    tbody tr:first-child th {
        padding-bottom: 15px;
        background-color: $table-head-secondary-bg;
        color: $table-head-secondary-color;
        border-bottom: 1px solid $table-border-color;
        font-family: $font-family-special;
        font-weight: $font-weight-bold;
        font-size: 25px;
        line-height: 1.2;

        .is-highlighted & {
            color: $black;
            border-bottom-color: rgba(0, 0, 0, .2);
        }
    }

    td {
        border-bottom: 1px solid $table-border-color;

        .is-highlighted & {
            border-bottom-color: rgba(0, 0, 0, .2);
        }
    }

    tr:last-child {
        td {
            @include media-breakpoint-up(md) {
                border-bottom: none;
            }
        }
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    /*
    // Spezial Responsive Table
    @include media-breakpoint-down(md) {
        table, thead, tbody, tr, th, td {
            display: block;
        }

        tr:last-child {
            td:last-child {
                border-bottom: 0;

                &:before {
                    border-bottom: 0;
                }
            }
        }

        tbody {
            tr {
                margin-bottom: 30px;

                td:last-child {
                    &, &:before {
                        border-bottom: none;
                    }
                }
            }
        }

        &.has-thead {
            thead tr:last-child {
                display: none;
            }

            tbody {
                td {
                    display: flex;
                    position: relative;
                    padding: $table-padding-y $table-padding-x $table-padding-y 0;
                    align-items: center;
                    align-content: center;

                    &:before {
                        display: flex;
                        align-items: center;
                        align-content: center;
                        content: attr(data-label);
                        margin-top: -$table-padding-y;
                        margin-bottom: -$table-padding-y - 1px;
                        margin-right: $table-padding-x;
                        padding: $table-padding-y $table-padding-x;
                        width: 45%;
                        flex: 0 0 45%;
                        font-weight: $font-weight-bold;
                        font-family: $font-family-special;
                        align-self: stretch;
                        @include hyphens-auto();
                    }
                }
            }

            &:not(.multiple-thead-rows) {
                tbody td:before {
                    background: $brand-primary;
                    border-bottom: 1px solid rgba(0, 0, 0, .2);
                    color: $black;

                    .is-highlighted:not(.white) & {
                        background: $black;
                        color: $white;
                        border-bottom-color: $table-border-color;
                    }
                }
            }

            &.multiple-thead-rows.second-thead-in-tbody {
                thead tr:last-child {
                    display: block;
                }

                tbody tr:first-child {
                    display: none;
                }
            }
        }
    }*/
}

@include media-breakpoint-down(md) {
    .responsive-table {
        overflow-x: auto;
    }
}