.badge {
    $badge--font-size: 40px;
    position: relative;
    width: to-em(242px, $badge--font-size);
    height: to-em(242px, $badge--font-size);
    background: $brand-primary;
    border-radius: 100%;
    text-align: center;
    overflow: hidden;
    transform: rotateZ(10deg);
    transition: transform .3s;
    @include fluid-type(map-get($grid-breakpoints, xl), map-get($grid-breakpoints, xxl), 30px, $badge--font-size);

    @include media-breakpoint-down(md) {
        font-size: 25px;
    }

    @include media-breakpoint-up(sm) {
        animation-name: pulse;
        animation-duration: 3s;
        animation-timing-function: ease-out;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-play-state: running;
    }

    &,
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: $black;
    }

    &:hover {
        animation-play-state: paused;
    }

    > .icon {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 50%;
        margin-bottom: to-em(35px, $badge--font-size);
        width: to-em(30px, $badge--font-size);
        height: to-em(30px, $badge--font-size);
        transform: translateX(-50%);
        filter: saturate(0) brightness(0) invert(0) opacity(1);
    }

    > span {
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        line-height: to-em(60px, $badge--font-size);
        white-space: nowrap;
        background: $brand-primary;
        font-family: $font-family-special;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        z-index: 2;
    }

    > small {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: 60%;
        margin-top: to-em(25px, 18px);
        transform: translateX(-50%);
        font-size: to-em(18px, $badge--font-size);
        line-height: 1.11111;
        z-index: 3;
    }

    &:after {
        display: block;
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        width: to-em(195px, $badge--font-size);
        height: to-em(195px, $badge--font-size);
        transform: translate(-50%, -50%);
        border: 9px solid $black;
        border-radius: 100%;
        z-index: 1;
    }
}

.badge--khs {
    background-color: $white;
    position: absolute;
    right: 60px;
    margin-top: 60px;
    z-index: 1;
    @include fluid-property('top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 70px, $header-top-height);
    @include fluid-property('right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 60px);

    @include media-breakpoint-down(sm) {
        margin-top: 0;
        top: 70px;
        left: 0;
        right: 0;
        display: flex;
        align-items: start;
    }

    &-headline {
        font-family: $font-family-special;
        font-weight: 600;
        display: block;
        @include fluid-type($viewport-width-min, $viewport-width-max, 14px, 19px);
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 5px;
        }
    }

    &-subheadline {
        @include fluid-type($viewport-width-min, $viewport-width-max, 13px, 12px);
        line-height: 13px;
        display: block;
    }

    &-text {
        padding: 30px 30px 30px 10px;
        max-width: 250px;
        color: $black;

        @include media-breakpoint-down(sm) {
            padding: 0 15px 0 0;
            margin: 15px 0;
            max-width: unset;
            width: 100%;
            border-right: 1px solid #E8E8E8;
        }
    }

    &-top {
        display: flex;
        align-items: start;

        img {
            width: 100%;
            height: auto;
            max-width: 105px;

            @include media-breakpoint-down(sm) {
                max-width: 71px;
                margin-top: 5px;
            }
        }
    }

    &-bottom {
        padding: 10px 35px;
        border-top: 1px solid #E8E8E8;

        &:before {
            content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.2 5.99994C7.2 6.22504 7.1064 6.44043 6.93995 6.59643L1.43424 11.7689C1.0969 12.0857 0.560708 12.0755 0.236494 11.7462C-0.0877203 11.4168 -0.0773437 10.8929 0.26 10.5761L5.13083 5.99994L0.260211 1.42394C-0.0769202 1.10738 -0.0877203 0.583305 0.236705 0.253921C0.561131 -0.0754629 1.09711 -0.0858079 1.43445 0.231162L6.94016 5.40365C7.1064 5.55945 7.2 5.77483 7.2 5.99994Z' fill='black'/%3E%3C/svg%3E%0A");
            height: 42px;
            width: 42px;
            background-color: $brand-primary;
            position: absolute;
            right: 25px;
            top: calc(100% - 20px);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @include media-breakpoint-down(sm) {
            padding: 15px 5px 15px 0;
            max-width: 150px;
            width: 100%;

            img {
                max-width: 145px;
                width: 100%;
                height: auto;
            }
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1) rotateZ(10deg);
    }
    30% {
        transform: scale(1.2) rotateZ(10deg);
    }
    60% {
        transform: scale(1) rotateZ(10deg);
    }
}