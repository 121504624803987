@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../fonts/googlefonts/Barlow_Condensed-normal-300.woff2) format('woff2'), url(../fonts/googlefonts/Barlow_Condensed-normal-300.woff) format('woff'), url(../fonts/googlefonts/Barlow_Condensed-normal-300.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../fonts/googlefonts/Barlow_Condensed-normal-400.woff2) format('woff2'), url(../fonts/googlefonts/Barlow_Condensed-normal-400.woff) format('woff'), url(../fonts/googlefonts/Barlow_Condensed-normal-400.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow Condensed';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(../fonts/googlefonts/Barlow_Condensed-normal-600.woff2) format('woff2'), url(../fonts/googlefonts/Barlow_Condensed-normal-600.woff) format('woff'), url(../fonts/googlefonts/Barlow_Condensed-normal-600.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Zilla Slab';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(../fonts/googlefonts/Zilla_Slab-italic-400.woff2) format('woff2'), url(../fonts/googlefonts/Zilla_Slab-italic-400.woff) format('woff'), url(../fonts/googlefonts/Zilla_Slab-italic-400.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Zilla Slab';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url(../fonts/googlefonts/Zilla_Slab-italic-600.woff2) format('woff2'), url(../fonts/googlefonts/Zilla_Slab-italic-600.woff) format('woff'), url(../fonts/googlefonts/Zilla_Slab-italic-600.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Zilla Slab';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../fonts/googlefonts/Zilla_Slab-normal-400.woff2) format('woff2'), url(../fonts/googlefonts/Zilla_Slab-normal-400.woff) format('woff'), url(../fonts/googlefonts/Zilla_Slab-normal-400.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Zilla Slab';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(../fonts/googlefonts/Zilla_Slab-normal-600.woff2) format('woff2'), url(../fonts/googlefonts/Zilla_Slab-normal-600.woff) format('woff'), url(../fonts/googlefonts/Zilla_Slab-normal-600.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

