.infobox {
    padding: 15px;
    border: 3px dashed $brand-primary;

    > *:last-child {
        margin-bottom: 0;
    }

    .is-highlighted:not(.white) & {
        &:not(.infobox-primary) {
            border-color: $black;
        }
    }

    &-primary {
        border-style: solid;
        background: $brand-primary;
        color: $white;

        a:not(.btn) {
            color: $white;

            &:hover {
                color: rgba($white, .8);
            }
        }

        .is-highlighted:not(.white) & {
            background: $black;
            border-color: $black;
        }
    }
}