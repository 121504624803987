.page-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($black, 0.75);
    z-index: $page-overlay--z-index;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
}