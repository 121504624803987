.area--tabs {
    .tab-nav,
    .tab-content {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    // accordion fallback für kleine endgeräte
    .area--accordion {
        display: none;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    .tab-pane {
        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}