.area--grid {
    padding-right: $container-padding;

    max-width: 1920px - ((1920px - $container-max-width--narrow) / 2) + $container-padding;

    @include media-breakpoint-down(lg) {
        padding-right: 0;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-row-gap: $grid-gutter-width;
        grid-column-gap: $grid-gutter-width;
        grid-template-areas:
                        "large large large small-1"
                        "large large large small-2"
                        "small-3 wide wide small-4";
        width: 100%;
        max-width: calc(50vw + (#{$container-max-width--narrow} / 2));
        max-width: calc(50vw + (#{$container-max-width--narrow} / 2) - (var(--scrollbarWidth) / 2));

        @include media-breakpoint-down(lg) {
            grid-column-gap: $grid-gutter-width / 2;
            grid-row-gap: $grid-gutter-width / 2;
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr 1.65fr 1fr 1.65fr;
            grid-template-areas:
                        "small-1 small-2"
                        "large large"
                        "small-3 small-4"
                        "wide wide";
        }

        &--small {
            grid-template-rows: 1fr;
            grid-template-areas: "small-3 wide wide small-4";

            @include media-breakpoint-down(md) {
                grid-template-rows: 1fr 1.65fr;
                grid-template-areas:
                        "small-3 small-4"
                        "wide wide";
            }
        }

        > [class*="grid-item--"] {
            position: relative;
            background: $brand-primary;
            text-align: center;
            overflow: hidden;

            font-family: $font-family-special;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 40px);

            @include media-breakpoint-only(md) {
                font-size: 25px;
            }

            @include media-breakpoint-down(md) {
                font-size: 18px;
            }

            &, a {
                color: $black;
                text-decoration: none;
            }

            .icon {
                width: 2em;
                height: 2em;
                filter: saturate(0) brightness(0) invert(0) opacity(1);

                @include media-breakpoint-down(lg) {
                    width: 60px;
                    max-width: 30%;
                    height: auto;

                    &:before {
                        display: block;
                        content: '';
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                    }
                }
            }

            &.has-media {
                .grid-item--text {
                    color: $brand-primary;

                    &:before {
                        display: block;
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(0, 0, 0, .6);
                        z-index: 0;
                        pointer-events: none;
                    }

                    > * {
                        z-index: 1;
                    }
                }

                a {
                    position: relative;
                    color: $brand-primary;
                }

                .icon {
                    filter: none;
                }
            }

            img,
            video {
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .video--play-pause {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 5;
            }
        }
    }

        .grid-item {
            &--inner {
                width: 100%;
                height: 100%;

                > a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

                &--text {
                    display: flex;
                    position: relative;
                    padding: 1.25em 20px;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: stretch;
                    height: 100%;
                    z-index: 2;

                    @include media-breakpoint-down(lg) {
                        align-items: center;
                        align-content: center;
                    }

                    p, > div {
                        line-height: 1.2;
                        margin: 0;
                        width: 100%;

                        @include media-breakpoint-only(md) {
                            margin-bottom: 15px;
                        }

                        @include media-breakpoint-only(sm) {
                            margin-bottom: 25px;
                        }

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 15px;
                        }
                    }

                    span {
                        display: block;
                        margin: 0;
                        width: 100%;
                        font-family: $font-family-base;
                        text-transform: none;
                        font-weight: $font-weight-normal;
                        line-height: 1.15;
                        @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 18px, 22px);

                        @include media-breakpoint-down(md) {
                            font-size: 16px;
                        }
                    }
                }

            &--large {
                grid-area: large;
            }

            &--small {
                position: relative;

                &:before {
                    display: block;
                    content: '';
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                }

                .grid-item--inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                &.small-1 {
                    grid-area: small-1;
                }

                &.small-2 {
                    grid-area: small-2;
                }

                &.small-3 {
                    grid-area: small-3;
                }

                &.small-4 {
                    grid-area: small-4;
                }
            }

            &--wide {
                grid-area: wide;
            }
        }
}