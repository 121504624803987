.lang-nav {

    a {
        display: block;
        color: $white;
        font-family: $font-family-special;
        line-height: 1;
        font-size: 32px;
        font-weight: $font-weight-light;
        transition: color .3s;

        @include media-breakpoint-only(md) {
            font-size: 28px;
        }

        @include media-breakpoint-down(md) {
            font-size: 24px;
        }

        &:hover {
            color: $brand-primary;
        }
    }
}