.burger {
    position: relative;
    margin: 0;
    padding: 16px 0;
    width: 36px;
    height: auto;
    border: none;
    cursor: pointer;

    @include media-breakpoint-down(md) {
        padding: 10px 0;
        width: 28px;
    }

    span {
        transition: background-color .3s;

        &,
        &:before,
        &:after {
            display: block;
            width: 100%;
            height: 3px;
            background: $black;

            @include media-breakpoint-down(md) {
                height: 3px;
            }
        }

        &:before,
        &:after {
            position: absolute;
            content: '';
            transform-origin: 50% 50%;
            transition: transform .3s;
        }

        &:before {
            top: 3px;

            @include media-breakpoint-down(md) {
                top: 0;
            }
        }

        &:after {
            bottom: 3px;

            @include media-breakpoint-down(md) {
                bottom: 0;
            }
        }
    }

    &--close {
        span {
            background-color: transparent;

            &:before {
                top: auto;
                transform: rotateZ(45deg);

                @include media-breakpoint-down(md) {
                    top: auto;
                }
            }

            &:after {
                bottom: auto;
                transform: rotateZ(-45deg);

                @include media-breakpoint-down(md) {
                    bottom: auto;
                }
            }
        }
    }
}