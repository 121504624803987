[name="toggleNav"],
[name="toggleSubnav"] {
    display: none;
}

[name="toggleNav"] {
    &:checked ~ .page-wrap {
        .page-overlay {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .main-nav {
            transform: translateX(0);

            &--toggle {
                &.burger {
                    span {
                        @include media-breakpoint-down(md) {
                            background: transparent;
                        }

                        &:before {
                            @include media-breakpoint-down(md) {
                                top: auto;
                                transform: rotateZ(45deg);
                            }
                        }

                        &:after {
                            @include media-breakpoint-down(md) {
                                bottom: auto;
                                transform: rotateZ(-45deg);
                            }
                        }
                    }
                }
            }
        }
    }
}

.main-nav {
    display: block;
    position: fixed;
    @include fluid-property('top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $header-top-height--min, $header-top-height);
    right: 0;
    bottom: 0;
    padding: 50px 150px 0;
    width: 820px;
    transform: translateX(100%);
    background: $brand-primary;
    transition: transform .3s ease-out;
    z-index: $page-overlay--z-index + 1;

    @include media-breakpoint-only(md) {
        top: $header-top-height--tablet;
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        top: 70px;
        padding: 25px 30px 0;
        width: 100%;
    }

    ul {
        @include reset-list;
    }

    a {
        color: $black;
        font-family: $font-family-special;
        line-height: 1.2;
    }

    &--toggle {
        .frontpage &, .white-layout & {
            display: none;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        &.burger {
            span {
                &,
                &:before,
                &:after {
                    background: $white;
                }
            }

            &--close {
                span {
                    background-color: transparent;
                }
            }
        }
    }

    .burger--close {
        position: absolute;
        top: 50px;
        right: 50px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &--top {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;

        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
        }
    }

        .lang-nav {
            vertical-align: top;

            a {
                color: $black;

                &:hover {
                    color: $black;
                }
            }
        }

        &--phone {
            font-size: 25px;
            font-weight: $font-weight-bold;
            white-space: nowrap;

            @include media-breakpoint-down(md) {
                font-size: 18px;
            }

            .icon {
                width: 30px;
                height: 30px;
                vertical-align: bottom;

                @include media-breakpoint-down(md) {
                    width: 21px;
                    height: 21px;
                }
            }

            span {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }

    &--inner {
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: calc(100% - 35px);

        @include media-breakpoint-down(md) {
            display: block;
            padding: 0 20px;
        }

        @media (max-width: 374px) {
            padding: 0;
        }
    }
}

ul.main-nav--list {
    width: 100%;
    margin: 10px 0;

    > li {
        position: relative;
        padding: 21px 0;
        border-bottom: 2px solid rgba($black, .2);

        @include media-breakpoint-down(md) {
            padding: 17px 0;
        }

        &:last-child {
            border-bottom: none;
        }

        [name="toggleSubnav"]:checked {
            ~ label {
                &:before {
                    transform: translate(-50%, -50%) rotateZ(90deg);
                }
            }

            ~ ul {
                max-height: 1000px;
            }
        }

        > label {
            display: block;
            position: absolute;
            top: 16px;
            right: 0;
            width: 40px;
            height: 40px;
            //border: 2px solid rgba($black, .2);
            border-radius: 100%;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                top: 9px;
                width: 30px;
            }

            &:before {
                display: block;
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                width: 14px;
                height: 14px;
                transform: translate(-50%, -50%);
                @include updateIcon('arrow', '');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                filter: saturate(0) brightness(0) invert(0) opacity(1);

                @include media-breakpoint-down(md) {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        > a {
            position: relative;
            padding-left: 80px;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            font-size: 25px;

            @include media-breakpoint-down(md) {
                padding-left: 40px;
                font-size: 18px;
            }

            .icon {
                position: absolute;
                left: 0;
                top: 50%;
                width: 35px;
                height: 30px;
                transform: translateY(-50%);

                @include media-breakpoint-down(md) {
                    width: 28px;
                    height: 24px;
                }

                &--sally-bold {
                    height: 34px;
                }
            }
        }

        > ul {
            max-height: 0;
            overflow: hidden;
            transition: max-height .5s;

            > li {
                margin-bottom: 5px;
                padding-left: 80px;

                @include media-breakpoint-down(md) {
                    padding-left: 40px;
                }

                &:first-child {
                    margin-top: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                > a {
                    position: relative;
                    padding-left: 15px;
                    font-size: 22px;
                    font-weight: $font-weight-normal;

                    @include media-breakpoint-down(md) {
                        padding-left: 12px;
                        font-size: 18px;
                        line-height: 1.1;
                    }

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 9px;
                        width: 7px;
                        height: 12px;
                        @include updateIcon(arrow, '');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center center;
                        filter: saturate(0) brightness(0) invert(0) opacity(1);

                        @include media-breakpoint-down(md) {
                            top: 7px;
                            width: 6px;
                            height: 9px;
                        }
                    }
                }
            }
        }
    }
}

.scroll-direction--scrolled {

    .main-nav {
        @include fluid-property('top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $header-top-height--min-scrolled, $header-top-height-scrolled);

        @include media-breakpoint-only(md) {
            top: $header-top-height--tablet-scrolled;
        }

        @include media-breakpoint-down(md) {
            top: $header-top-height--mobile-scrolled;
        }
    }
}