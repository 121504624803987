.main-content {
    @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 175px);
    @include fluid-property('padding-bottom', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 175px);

    @include media-breakpoint-only(md) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 50px;
        padding-bottom: 50px;
    }


    .blog--list &,
    .blog--detail & {
        @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 150px);

        @include media-breakpoint-only(md) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @include media-breakpoint-down(md) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    .blog--list & {
        @include fluid-property('padding-bottom', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 100px, 150px);
    }

    .blog--detail & {
        background: $blog-bg;
        color: $black;

        @include media-breakpoint-up(lg) {
            padding-bottom: 50px;
        }
    }

    @at-root  {
        :root {
            --scrollbarWidth: 0px;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}