.area--header {

    .main-content > & {
        padding-left: $container-padding;
        padding-right: $container-padding;

        @include media-breakpoint-down(sm) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    &.is-special {
        $special-header-text-size: 55px;
        position: relative;
        @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 40px, $special-header-text-size);

        &, .main-content > & {
            padding-left: 0;
            padding-right: 0;
        }

        h1,
        h2,
        h3 {
            display: flex;
            position: relative;
            padding-left: to-em(180px, $special-header-text-size);
            width: to-em(750px, $special-header-text-size);
            height: to-em(180px, $special-header-text-size);
            color: $black;
            background: $brand-primary;
            font-size: 1em;
            font-family: $font-family-special;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            line-height: 1;
            align-items: center;

            @include media-breakpoint-down(md) {
                width: 300px;
                height: 100px;
                padding-left: 100px;
                font-size: 25px;
                line-height: 1.2;
            }

            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                bottom: 0;
                width: to-em(180px, $special-header-text-size);
                background: $black;

                @include media-breakpoint-down(md) {
                    width: 100px;
                }
            }

            .icon {
                position: absolute;
                top: 50%;
                left: to-em((180px / 2), $special-header-text-size);
                width: to-em(80px, $special-header-text-size);
                height: to-em(80px, $special-header-text-size);
                transform: translate(-50%, -50%);

                @include media-breakpoint-down(md) {
                    left: 50px;
                    width: 60px;
                    height: 60px;
                }
            }

            span {
                display: block;
                margin: 0;
                padding: 0 30px;

                @include media-breakpoint-down(md) {
                    padding: 0 20px;
                }
            }
        }

        .area--header--special-subline {
            display: block;
            position: absolute;
            left: to-em(750px, $special-header-text-size);
            top: 50%;
            margin: 0 0 0 $container-padding;
            padding-right: $container-padding;
            width: calc(100vw - #{$container-padding} - #{to-em(750px, $special-header-text-size)});
            width: calc(100vw - #{$container-padding} - #{to-em(750px, $special-header-text-size)} - var(--scrollbarWidth));
            max-width: 570px;
            transform: translateY(-50%);
            text-transform: none;
            color: $brand-primary;
            font-weight: $font-weight-bold;
            font-family: $font-family-special;

            @include media-breakpoint-down(lg) {
                position: relative;
                left: auto;
                top: auto;
                width: 100%;
                transform: none;
            }

            @include media-breakpoint-only(md) {
                margin: $container-padding 0 0 0;
                padding: 0 0 0 $container-padding;
                max-width: calc(#{to-em(750px, $special-header-text-size)});
            }

            @include media-breakpoint-down(md) {
                max-width: none;
            }

            @include media-breakpoint-only(sm) {
                margin: 30px 0 0 0;
                padding: 0 $container-padding;
            }

            @include media-breakpoint-down(sm) {
                margin: 30px 0 0 0;
                padding: 0 40px;
            }

            div {
                font-size: .75em;
                line-height: 1.25;

                @include media-breakpoint-down(lg) {
                    font-size: .625em;
                }
            }
        }
    }
}