.page-header {
    position: relative;
    @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $header-top-height--min, $header-top-height);
    height: 100vh;
    min-height: 550px;

    @include media-breakpoint-only(lg) {
        min-height: 400px;
    }

    @include media-breakpoint-only(md) {
        padding-top: $header-top-height--tablet;
        min-height: 370px;
    }

    @include media-breakpoint-down(md) {
        padding-top: $header-top-height--mobile;
        min-height: 300px;
    }

    &.narrow {
        height: 70vh;
    }

    &.no-media {
        height: auto;
        min-height: 0;
    }

    &.special {
        @include media-breakpoint-up(lg) {
            max-height: 1024px;
        }

        @include media-breakpoint-down(lg) {
            height: auto;
        }
    }

    &.has-video:not(.special) {
        @include media-breakpoint-down(sm) {
            height: auto;

            .badge {
                position: relative;
                left: auto;
                top: auto;
                right: auto;
            }
        }
    }

    &--top {
        display: flex;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        @include fluid-property('height', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $header-top-height--min, $header-top-height);
        background: $black;
        z-index: $page-overlay--z-index - 1;
        transition: height .15s;
        flex-wrap: nowrap;

        @include media-breakpoint-only(md) {
            height: $header-top-height--tablet;
        }

        @include media-breakpoint-down(md) {
            height: $header-top-height--mobile;
        }
    }

        .logo-wrap {
            position: relative;
            display: block;
            flex-grow: 0;
            width: $header-logo-width;
            height: 100%;
            background: $brand-primary;

            @include media-breakpoint-only(md) {
                max-width: $header-logo-width--tablet;
            }

            @include media-breakpoint-down(md) {
                flex: 0 1 auto;
                width: $header-logo-width--mobile;
                min-width: 80px;
            }

            .logo {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 62%;
                max-height: 60%;
                transform: translate(-50%, -50%);

                @include media-breakpoint-down(md) {
                    max-height: none;
                }
            }
        }

    &--nav {
        display: flex;
        flex: 1 0;
        @include fluid-property('padding-left', map-get($grid-breakpoints, xxl), map-get($grid-breakpoints, outer), 50px, 150px);
        @include fluid-property('padding-right', map-get($grid-breakpoints, xxl), map-get($grid-breakpoints, outer), 50px, 150px);
        height: 100%;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;

        body.landingpage & {
            justify-content: flex-start;

            @include media-breakpoint-down(md) {
                padding-right: 10px;
            }
        }

        @include media-breakpoint-only(md) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @include media-breakpoint-down(md) {
            padding-left: 10px;
            padding-right: 30px;
        }

        .spacer {
            @include fluid-property('margin-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 50px);
            @include fluid-property('margin-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 50px);
            width: 0;
            @include fluid-property('height', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 85px, 100px);
            border-right: 2px solid $brand-primary;

            @include media-breakpoint-only(md) {
                margin-left: 25px;
                margin-right: 25px;
                height: 60px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .tickets-mobile {
            display: none;

            @include media-breakpoint-down(md) {
                display: block;
                margin-right: 15px;
            }

            .icon {
                width: 24px;
                height: 24px;
                line-height: 1;
                vertical-align: bottom;
                filter: saturate(0) brightness(0) invert(100%) opacity(1);
            }
        }

        > .lang-nav {
            display: none;

            .frontpage & {
                display: block;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        > .main-nav--toggle {
            span {
                &,
                &:before,
                &:after {
                    transition: top .2s, bottom .2s, background-color .2s;
                }
            }

            &:hover {
                span {
                    &,
                    &:before,
                    &:after {
                        background: $brand-primary;
                    }

                    &:before {
                        top: 1px;
                    }

                    &:after {
                        bottom: 1px;
                    }
                }
            }
        }
    }

    .badge {
        position: absolute;
        @include fluid-property('top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $header-top-height--min, $header-top-height);
        right: 60px;
        margin-top: 60px;
        z-index: 1;

        @include media-breakpoint-between(sm, lg) {
            right: 30px;
            margin-top: 0;
        }

        @include media-breakpoint-down(sm) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: nowrap;
            left: 0;
            right: 0;
            top: 70px;
            margin-top: 0;
            padding: 10px 20px;
            width: 100%;
            height: 65px;
            transform: rotateZ(0);
            border-radius: 0;

            &:after {
                left: 10px;
                top: 10px;
                right: 10px;
                bottom: 10px;
                width: auto;
                height: auto;
                transform: translate(0, 0);
                border-width: 2px;
                border-radius: 5px;
            }

            > * {
                position: static;
                margin: 0;
                padding: 0;
                transform: translate(0, 0);
                text-align: left;
            }

            > .icon {
                margin-right: 8px;
                width: 24px;
                height: 24px;
            }

            > span {
                margin-right: 12px;
                width: auto;
                font-size: 25px;
                background: none transparent;
            }

            > small {
                width: auto;
                font-size: 18px;
                line-height: 1.1;
            }
        }


    }

    &--landingpage {
        display: flex;
        @include fluid-property('padding-left', map-get($grid-breakpoints, xl), map-get($grid-breakpoints, xxl), 0px, 65px);
        width: 100%;
        max-width: 915px;
        align-items: center;
        align-content: center;
        justify-content: space-between;

        @include media-breakpoint-down(lg) {
            justify-content: flex-end;
        }

        @include media-breakpoint-down(md) {
            padding-left: 15px;
        }

        > a {
            display: block;
            flex: 0 1 auto;
            white-space: nowrap;
            margin: 0 $grid-gutter-width / 2;
            font-size: 25px;
            @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 20px, 25px);
            line-height: 1.2;

            @include media-breakpoint-down(md) {
                margin: 0 10px;
            }

            &:not(.btn) {
                color: $white;
                font-family: $font-family-special;
                font-weight: $font-weight-bold;

                .icon {
                    @include media-breakpoint-down(lg) {
                        margin-right: 0;
                    }
                }

                span {
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
            }

            &.btn {
                padding: to-em(14px, 25px) to-em(20px, 25px);
                text-transform: uppercase;

                @include media-breakpoint-down(md) {
                    padding: 10px 12px;
                }

                .icon {
                    vertical-align: middle;

                    @include media-breakpoint-only(sm) {
                        margin-right: 5px;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-right: 0;
                    }
                }

                span {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            .icon {
                margin-right: to-em(15px, 25px);
                width: to-em(30px, 25px);
                height: to-em(30px, 25px);
                vertical-align: bottom;

                &--envelope-bold {
                    width: to-em(35px, 25px);
                }
            }
        }
    }
}

.scroll-direction--scrolled {

    .page-header {

        &--top {

        }

        .spacer {
            @include fluid-property('height', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 55px, 70px);;

            @include media-breakpoint-only(md) {
                height: 30px;
            }
        }
    }

    .page-header--top {
        @include fluid-property('height', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), $header-top-height--min-scrolled, $header-top-height-scrolled);

        @include media-breakpoint-only(md) {
            height: $header-top-height--tablet-scrolled;
        }

        @include media-breakpoint-down(md) {
            height: $header-top-height--mobile-scrolled;
        }

        @include media-breakpoint-up(md) {
            .meta-nav > ul > li {

                & > a, & > label {
                    .icon:not(icon--map-pin-bold) {
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                    }
                }
            }
        }
    }
}