$logo-width: 205px;
$logo-height: 87px;
$logo-ratio: (strip-units($logo-height) * 100) / strip-units($logo-width);

.logo {
    display: block;
    width: 100%;
    max-width: 400px;

    background-image: url('../images/layout/svg/logo.svg');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    text-indent: -9999em;
    overflow: hidden;
    font-size: 0;

    &:after {
        display: block;
        content: '';
        width: 100%;
        height: 0;
        padding-bottom: $logo-ratio + 0%;
    }
}