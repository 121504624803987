.job-benefits {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    position: relative;

    &--infotext {
        flex: 1 1 250px;
    }

    &--list {
        flex: 0 0 auto;
        position: relative;
        max-width: 100%;

        &-wrapper {
            position: sticky;
            top: 120px;
        }

        h5 {
            margin-bottom: 1rem;
        }

        li {
            margin-bottom: 10px;
        }
    }

    &--headline-text {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 3rem;
        @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 15px, 30px);

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        h5 {
            flex: 0 0 100%;
            margin-top: 1rem;
            max-width: 150px;
        }

        p, ul {
            flex: 1 1 240px;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                flex: 1 1 auto;
            }
        }
    }
}