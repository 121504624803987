.area--sitemap {

    .main-content > & {
        margin-left: auto;
        margin-right: auto;
        padding-left: $container-padding;
        padding-right: $container-padding;
        max-width: $container-max-width--narrow + ($container-padding * 2);

        @include media-breakpoint-down(sm) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    font-family: $font-family-special;

    a, a:not(.btn) {
        display: inline-block;
        text-decoration: none;
    }

    > nav {
        > ul {
            display: flex;
            flex-wrap: wrap;

            > li {
                flex: 1 1 25%;
                margin-bottom: 30px;

                &:before {
                    display: none;
                }

                > a {
                    margin-bottom: .25em;
                    @include fluid-type($viewport-width-min, $viewport-width-max, $h3-min-font-size, $h3-font-size);
                    color: $brand-primary;
                    font-weight: $font-weight-bold;

                    &:hover {
                        color: $white;
                    }
                }

                > ul {
                    a {
                        color: $white;

                        &:hover {
                            color: $brand-primary;
                        }
                    }

                    > li {
                        > a {
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }
        }
    }
}