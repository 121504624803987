.area--cols {

    .main-content > & {
        padding-left: $container-padding;
        padding-right: $container-padding;

        @include media-breakpoint-down(sm) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    @include media-breakpoint-down(xl) {
        .area.is-narrow {
            padding-left: 0;
            padding-right: 0;
        }
    }
}