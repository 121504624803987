.flatpickr-calendar {
    font-family: $font-family-special;
    font-size: 16px;
}

.flatpickr-months .flatpickr-month {
    color: $black;
}

.flatpickr-day {
    color: $black;
}

.flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover,
.flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover,
.flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
    background: $brand-primary;
    border-color: $brand-primary;
    color: $black;
    font-weight: $font-weight-bold;
}