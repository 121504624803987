[name="toggleSearch"] {
    display: none;

    &:checked ~ .page-wrap {
        .page-overlay,
        .header-search--container {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }

        .header-search--container {

        }
    }
}

.header-search {

    &--toggle {
        display: block;
        @include fluid-property('margin-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 30px, 50px);
        cursor: pointer;

        @include media-breakpoint-only(md) {
            margin-right: 25px;
        }

        .icon {
            width: 30px;
            height: 30px;

            @include media-breakpoint-only(md) {
                width: 25px;
                height: 25px;
            }

            @include media-breakpoint-down(md) {
                width: 24px;
                height: 24px;
            }
        }

        &:hover {
            .icon {
                filter: none;
            }
        }

        &.burger--close {
            position: absolute;
            top: 120px;
            right: -110px;
            margin: 0;
            z-index: $page-overlay--z-index + 1;

            @include media-breakpoint-only(md) {
                top: 80px;
                right: -70px;
            }

            @include media-breakpoint-down(md) {
                top: 50px;
                right: -83px;
            }

            span {
                &:before, &:after {
                    background-color: $brand-primary;
                }
            }
        }
    }

    &--container {
        display: block;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity .3s, visibility .3s;
        z-index: $page-overlay--z-index + 1;

        &--inner {
            position: fixed;
            left: 50%;
            top: 50%;
            @include fluid-property('margin-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), ($header-top-height--min / 2), ($header-top-height / 2));
            width: 810px;
            max-width: 100%;
            transform: translate(-50%, -50%);
            z-index: $page-overlay--z-index + 1;
        }

        form {
            padding: 0 50px;
        }

        .form-group {
            position: relative;
            margin: 0 0 15px;
            padding: 0 0 15px 80px;
            border-bottom: 2px solid $brand-primary;

            button[type="submit"] {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                margin: 0;
                padding: 0;
                width: 80px;
                height: 80px;
                cursor: pointer;
                border: none;
                background: none transparent;

                @include media-breakpoint-down(md) {
                    width: 60px;
                    height: 60px;
                }
            }

                .icon {
                    display: block;
                    width: 100%;
                    height: 100%;
            }

            input[type="text"] {
                width: 100%;
                height: 80px;
                line-height: 80px;
                padding: 0 0 0 50px;
                margin: 0;
                border: none;
                border-radius: 0;
                font-size: 55px;
                color: $brand-primary;
                font-family: $font-family-special;
                font-weight: $font-weight-bold;
                background: none transparent;

                @include media-breakpoint-down(md) {
                    height: 60px;
                    line-height: 60px;
                    font-size: 25px;
                    padding: 0;
                }

                &:focus {
                    outline: 0;
                }

                &::placeholder {
                    color: $brand-primary;
                    text-transform: uppercase;
                }
            }

            .form-hint {
                font-size: 18px;
            }
        }

    }
}