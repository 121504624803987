.widget {
    position: fixed;
    right: 0;
    bottom: 20%;
    z-index: $page-overlay--z-index;
    max-width: 310px;
    font-family: $font-family-special;
    display: grid;
    grid-template-columns: 20px 1fr;
    background-color: $brand-primary;
    transform: translateX(calc(100% - 20px));
    transition: transform .4s;

    @include media-breakpoint-up(sm) {
        grid-template-columns: 30px 1fr;
        max-width: 550px;
        transform: translateX(calc(100% - 30px));
    }

    &-container {
        padding: 10px 0;

        @include media-breakpoint-up(sm) {
            padding: 20px 0;

            & > a:hover {

                .widget-content {
                    transform: translateX(-20px);
                }
            }
        }
    }

    &-content {
        background-color: $black;
        padding: 10px;
        transition: transform .4s;

        @include media-breakpoint-up(sm) {
            padding: 15px;
        }

        p {
            margin-bottom: 0;
        }
    }

    &-open {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        visibility: visible;

        svg {
            width: 15px;
            height: 15px;
            opacity: 1;
            transition: opacity .4s;
        }
    }

    &-close {
        position: absolute;
        right: 20px;
        height: 20px;
        width: 20px;
        top: -20px;
        background-color: $brand-primary;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(sm) {
            right: 25px;
            height: 25px;
            width: 25px;
            top: -25px;
        }

        svg {
            width: 13px;
            height: 13px;
        }
    }

    label {
        cursor: pointer;
    }

    a {
        color: inherit;
    }

    h3 {
        color: $white;
        margin-bottom: .5em;
    }
}

.white-orange {

    &.widget {
        background-color: $white;

        .widget-content {
            background-color: $brand-primary;
        }

        .widget-close {
            background-color: $white;

            svg {
                filter: brightness(0) saturate(100%);
            }
        }

        h3 {
            color: $brand-primary;
        }
    }
}

.black-orange {

    &.widget {
        background-color: $black;

        .widget-content {
            background-color: $brand-primary;
        }

        .widget-close {
            background-color: $black;
        }

        .widget-open {

            svg {
                filter: brightness(0) invert(1);
            }
        }

        h3 {
            color: $white;
        }
    }
}

#toggleWidget {
    display: none;

    &:checked {

        & ~ .widget {
            transform: translateX(0);

            .widget-open {
                visibility: hidden;

                svg {
                    opacity: 0;
                }
            }
        }
    }
}