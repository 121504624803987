/*** Variables ***/
$events--color: #333333;
$events--gutter: 30px;
$events--border-light: rgba(0, 0, 0, .1);
$events--bg-gray: #e4e4e4;

$events-filter--bg: $brand-primary;
$events-filter--input-height: 40px;
$events-filter--input-bg: #fff;
$events-filter--input-border-color: $events--border-light;

$events-item--color: $events--color;
$events-item--bg: $brand-primary;
$events-item--label-bg: #ff0000;
$events-item--label-color: #fff;

$events-header--color: #fff;

$events-btn--color: #fff;
$events-btn--color-hover: #fff;
$events-btn--bg: #69BFAC;
$events-btn--bg-hover: $events--color;
$events-btn-outline--color: $events--color;
$events-btn-outline--color-hover: #fff;
$events-btn-outline--bg: #fff;
$events-btn-outline--bg-hover: $events--color;
$events-btn-outline--border: $events--border-light;
$events-btn-outline--border-hover: $events--color;

$events-pagination--color: $events--color;
$events-pagination--border-color: $events--bg-gray;
$events-pagination--bg: transparent;
$events-pagination--bg-active: $events-pagination--border-color;

$icon-color-filter--gray: invert(18%) sepia(1%) saturate(0%) hue-rotate(11deg) brightness(97%) contrast(91%);
$icon-color-filter--white: saturate(0) brightness(0) invert(100%) opacity(1);;

/*** Styles ***/
.events {
    color: $events--color;

    &.full-width {
        max-width: unset;
        padding: 0;
    }

    // Events Filter
    &--filter {
        margin-bottom: $events--gutter;
        padding: 30px 30px 15px;
        background: $events-filter--bg;

        .btn-secondary {
            border-color: $black;
        }

        form {
            display: flex;
            flex-wrap: wrap;
            margin-left: -($events--gutter / 2);
            margin-right: -($events--gutter / 2);
        }

        &--col {
            position: relative;
            flex: 1 1 auto;
            padding: 0 ($events--gutter / 2);
            margin-bottom: 15px;

            @media (max-width: 767px) {
                flex-basis: 100%;
            }

            &:first-child {
                @media (max-width: 991px) {
                    flex-basis: 100%;
                }
            }

            &:last-child {
                @media (min-width: 768px) {
                    display: flex;
                    flex: 0 0 auto;
                    align-items: flex-end;
                }
            }

            label {
                display: block;
                margin-bottom: 8px;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: bold;
                color: $white;
            }

            input, select {
                display: block;
                padding: 8px 14px;
                width: 100%;
                height: 40px;
                background: $events-filter--input-bg;
                border: 1px solid $events-filter--input-border-color;
                border-radius: 5px;
                font-size: 14px;
            }

            button[type="submit"] {
                display: block;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .events--icon {
                display: block;
                position: absolute;
                bottom: ($events-filter--input-height / 2);
                right: ($events--gutter / 2) + 11px;
                transform: translateY(50%);
                filter: $icon-color-filter--gray;

                &--calendar {
                    width: 22px;
                    height: 18px;
                }
            }
        }
    }

    // Events List
    &--list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -($events--gutter / 2);
        margin-right: -($events--gutter / 2);

        &.compact {
            > .event {
                .event--text,
                .event--buttons {
                    display: none;
                }
            }
        }

        > .event {
            width: 50%;
            flex: 0 0 50%;

            .event--meta > p {
                margin-bottom: 0;
            }

            @media (min-width: 992px) {
                width: 33.333%;
                flex-basis: 33.333%;
            }

            @media (max-width: 767px) {
                width: 100%;
                flex-basis: 100%;
            }
        }

        &.page-1 {
            > .event {
                &:nth-child(1),
                &:nth-child(2) {
                    @media (min-width: 992px) {
                        width: 50%;
                        flex-basis: 50%;
                    }

                    .event--image {
                        &:before {
                            @media (min-width: 992px) {
                                padding-bottom: 56%;
                            }
                        }
                    }

                    .event--more {
                        @media (min-width: 992px) {
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    &--pagination {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: center;

        li {
            display: inline-block;
            margin-bottom: 4px;

            &.current {
                a {
                    &, &:hover {
                        background: $events-pagination--bg-active;
                    }
                }
            }

            a {
                display: block;
                width: 50px;
                height: 50px;
                border: 2px solid $events-pagination--border-color;
                border-radius: 100%;
                color: $events-pagination--color;
                line-height: 46px;
                font-size: 17px;
                transition: background-color .3s;

                &:hover {
                    background: rgba($events-pagination--bg-active, .3);
                }
            }
        }
    }

    // Events Detail
    &--detail {

        & > .area--header {
            h2, .h2 {
                padding-bottom: .375em;
                margin: 1em 0 .625em;
                border-bottom: 1px solid $events--border-light;
            }
        }

        header {

            h2, .h2 {
                padding-bottom: .375em;
                margin: 1em 0 .625em;
                border-bottom: 1px solid $events--border-light;
            }

            .event--meta {
                padding: 0;
                margin: 0 0 40px;
                font-size: 16px;

                p {
                    display: flex;
                }

                & > p:first-child {
                    margin-top: 1em;
                }

                i {
                    vertical-align: top !important;
                }

                span {
                    white-space: normal;
                }

                span p {
                    display: inline-block;
                    margin-bottom: 0;
                }

                .events--icon {
                    position: relative;
                    top: 2px;
                    width: 20px;
                    height: 16px;
                    vertical-align: baseline;
                    filter: $icon-color-filter--gray;
                }
            }
        }

        h4 {
            margin-bottom: 1.2em;
        }

        .event {
            &--media {
                margin-bottom: 30px;

                .embed-responsive-16by9 {
                    position: relative;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                > img,
                > a > img,
                > picture > img,
                > a > picture > img,
                > video {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            &--text {
                margin-bottom: 50px;
                padding: 0;
            }

            &--buttons {
                margin-bottom: 35px;
                padding: 0;

                .events--btn {
                    margin: 0 0 8px 5px;
                    width: auto;
                }
            }

            &--dates {
                margin-bottom: 30px;
            }

                &--date {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    align-content: center;
                    height: 54px;
                    border-bottom: 1px solid $events--border-light;

                    &:first-child {
                        border-top: 1px solid $events--border-light;
                    }

                    .event--meta {
                        flex: 1 1 auto;
                        margin: 0;
                        padding: 0;

                        .events--icon {
                            position: relative;
                            top: 2px;
                            filter: $icon-color-filter--gray;
                            vertical-align: baseline;
                        }
                    }

                    > a {
                        display: block;
                        flex: 0 0 auto;
                        font-size: 12px;
                        color: $events--color;

                        &:hover {
                            span {
                                text-decoration: none;
                            }
                        }

                        .events--icon {
                            font-size: 10px;
                        }

                        span {
                            text-decoration: underline;
                        }
                    }
                }

            &--downloads {
                margin-bottom: 30px;

                span {
                    word-break: break-word;
                }
            }

                &--download {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    align-content: center;
                    margin-bottom: 5px;
                    padding: 0 15px;
                    height: 50px;
                    background: $events--bg-gray;
                    color: $events--color;
                    font-size: 13px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover {
                        span {
                            text-decoration: none;
                        }
                    }

                    span {
                        text-decoration: underline;
                    }

                    .events--icon {
                        margin-right: 10px;

                        &--pdf {
                            font-size: 20px;
                        }

                        &--download {
                            font-size: 18px;
                        }
                    }
                }

            &--media-list {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-column-gap: $events--gutter;
                grid-row-gap: $events--gutter;
                margin-bottom: 30px;

                @media (min-width: 768px) and (max-width: 1199px) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media (min-width: 575px) and (max-width: 767px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 575px) {
                    grid-template-columns: 100%;
                }

                &--item {
                    position: relative;
                    width: 100%;

                    &:before {
                        display: block;
                        content: '';
                        width: 100%;
                        height: 0;
                        padding-bottom: 60%;
                    }

                    img, video {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            &--contact {
                background: $events--bg-gray;
                padding: 30px;

                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                a {
                    color: $events--color;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

        &--back.btn {
            display: inline-block;
            width: auto;
            text-transform: none;

            .events--icon {
                transform: rotateZ(180deg);
                font-size: 10px;
                vertical-align: baseline;
            }
        }

    // Buttons
    &--btn {
        display: inline-block;
        padding: 12px 25px;
        width: 100%;
        line-height: 1.3;
        background: $events-btn--bg;
        color: $events-btn--color;
        border: none;
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 13px;
        text-align: center;
        cursor: pointer;
        transition: background-color .3s, color .3s;

        &:hover {
            color: $events-btn--color-hover;
            background-color: $events-btn--bg-hover;

            .events--icon {
                &:hover {
                    filter: $icon-color-filter--gray;
                }
            }
        }

        .events--icon {
            margin: 0 4px 0 -8px;
            filter: $icon-color-filter--white;
            transition: filter .3s;
            font-size: 16px;
            vertical-align: bottom;
        }

        &--outline {
            padding: 11px 24px;
            border: 1px solid $events-btn-outline--border;
            background: $events-btn-outline--bg;
            color: $events-btn-outline--color;

            &:hover {
                color: $events-btn-outline--color-hover;
                background-color: $events-btn-outline--bg-hover;

                .events--icon {
                    filter: $icon-color-filter--white;
                }
            }

            .events--icon {
                filter: $icon-color-filter--gray;
            }
        }
    }
}

/*** Event Teaser ***/
.event {
    padding: 0 ($events--gutter / 2) $events--gutter;
    line-height: 1.15;

    a {
        text-decoration: none;
    }

    &--inner {
        position: relative;
        height: 100%;
        background: $events-item--bg;
        overflow: hidden;
        color: $white;

        > * {
            width: 100%;
            flex: 0 0 100%;
        }

        .btn-secondary {
            border-color: $black;
        }

        &:hover {
            .event {
                &--image {
                    img, video {
                        transform: scale(1.1);
                    }
                }

                &--more {
                    background-color: $events-btn--bg-hover;
                    color: $events-btn--color-hover;
                }
            }
        }
    }

        &--label {
            position: absolute;
            display: block;
            bottom: 100%;
            left: 50%;
            padding: 4px 0;
            font-size: 12px;
            font-weight: bold;
            background: $events-item--label-bg;
            color: $events-item--label-color;
            text-transform: uppercase;
            text-align: center;
            white-space: nowrap;
            transform: translate(-50px, 50px) rotateZ(45deg);
            transform-origin: 50% 100%;
            z-index: 2;
        }

        &--image {
            position: relative;
            overflow: hidden;

            &:before {
                display: block;
                content: '';
                padding-bottom: 75%;
                width: 100%;
                height: 0;
                pointer-events: none;
            }

            img, video {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform .3s;
            }

            header {
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 40px 30px 25px;
                width: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .4) 100%);

                &, h4 {
                    color: $events-header--color;
                }

                h4 {

                }
            }
        }

            &--meta {
                margin-top: 10px;
                padding-top: 15px;
                font-size: 14px;
                border-top: 1px solid rgba($events-header--color, .4);
                white-space: nowrap;

                span {
                    display: inline-block;
                    vertical-align: bottom;
                }

                .events--icon {
                    margin-right: 5px;
                    width: 16px;
                    height: 14px;
                    vertical-align: bottom;
                    filter: $icon-color-filter--white;
                }
            }

        &--text {
            padding: 30px 30px 25px;
            font-size: 16px;
            color: $events-item--color;
            line-height: 1.5625;

            > p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &--buttons {
            padding: 0 30px 40px;
            text-align: right;
            align-self: flex-end;
        }

            &--more {

            }
}

.area--infobox {
    margin-bottom: 30px;
}

/*** Icons ***/
.events--icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: inherit;
    vertical-align: baseline;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    filter: brightness(0) saturate(100%);

    &--search {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 17'%3E%3Cpath d='M6.067 1.545c1.207 0 2.343.47 3.197 1.325a4.49 4.49 0 011.324 3.196c0 1.208-.47 2.343-1.324 3.198a4.491 4.491 0 01-3.197 1.324A4.494 4.494 0 012.87 9.264a4.527 4.527 0 010-6.394 4.49 4.49 0 013.197-1.325zm0-1.545a6.067 6.067 0 10.001 12.133A6.067 6.067 0 006.067 0z'/%3E%3Cpath d='M14.596 15.726l1.13-1.131-4.941-4.941-1.13 1.131 4.941 4.94z'/%3E%3Cpath d='M10.784 9.927l4.669 4.668-.858.858-4.668-4.668.857-.858zm0-.546L9.38 10.786 14.595 16 16 14.596l-5.216-5.215z'/%3E%3C/svg%3E");
    }

    &--calendar {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 19'%3E%3Cpath d='M19.333 1.115c.916 0 1.667.69 1.667 1.533v13.027c0 .844-.751 1.533-1.667 1.533H2.667C1.75 17.207 1 16.518 1 15.674V2.648c0-.843.75-1.533 1.667-1.533h16.666zm0-.92H2.667C1.196.195 0 1.295 0 2.648v13.027c0 1.352 1.196 2.452 2.667 2.452h16.666c1.471 0 2.667-1.1 2.667-2.452V2.648C22 1.295 20.804.195 19.333.195z'/%3E%3Cpath d='M21 4.18V2.648c0-.843-.751-1.533-1.667-1.533H2.667C1.75 1.115 1 1.805 1 2.648V4.18l20-.001zM8.918 6.484H6.423V8.78h2.495V6.484zM15.577 6.484h-2.495V8.78h2.495V6.484zM18.907 6.484H16.41V8.78h2.497V6.484zM12.248 6.484H9.752V8.78h2.496V6.484zM8.918 9.545H6.423v2.295h2.495V9.545zM5.59 9.545H3.093v2.295H5.59V9.545zM15.577 9.545h-2.495v2.295h2.495V9.545zM18.907 9.545H16.41v2.295h2.497V9.545zM12.248 9.545H9.752v2.295h2.496V9.545zM8.918 12.607H6.423v2.295h2.495v-2.295zM5.59 12.607H3.093v2.295H5.59v-2.295zM15.577 12.607h-2.495v2.295h2.495v-2.295zM12.248 12.607H9.752v2.295h2.496v-2.295z'/%3E%3C/svg%3E");
    }

    &--calendar-sec {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 16'%3E%3Cpath d='M2.509 2.91a.727.727 0 00-.727.726v10.182c0 .402.325.728.727.728h10.182a.727.727 0 00.727-.728V3.636a.727.727 0 00-.727-.727H2.509zm-2.182.726c0-1.205.977-2.181 2.182-2.181h10.182c1.205 0 2.182.976 2.182 2.181v10.182A2.182 2.182 0 0112.69 16H2.509a2.182 2.182 0 01-2.182-2.182V3.636z'/%3E%3Cpath d='M10.509 0c.402 0 .727.326.727.727v2.91a.727.727 0 11-1.454 0V.726c0-.401.325-.727.727-.727zM4.69 0c.402 0 .728.326.728.727v2.91a.727.727 0 11-1.454 0V.726c0-.401.325-.727.727-.727zM.327 6.545c0-.401.326-.727.727-.727h13.091a.727.727 0 110 1.455H1.055a.727.727 0 01-.728-.728z'/%3E%3C/svg%3E");
    }

    &--angle {
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10'%3E%3Cpath d='M1.742 9L5 5 1.742 1' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }

    &--ticket {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 16'%3E%3Cpath fill-rule='evenodd' d='M15.426 6.983l-7.503 7.502-1.07-1.07a2.768 2.768 0 00-.749-2.647 2.792 2.792 0 00-1.977-.833c-.228 0-.453.029-.67.084l-1.069-1.07 7.569-7.435 1.061 1.061a2.86 2.86 0 00.758 2.657c.536.535 1.241.833 1.978.833.228 0 .452-.029.67-.084l1.002 1.002zm-.723-2.78l1.898 1.899a1.187 1.187 0 01-.067 1.83l-7.661 7.661c-.272.271-.61.407-.95.407-.339 0-.678-.136-.949-.407l-1.898-1.898a1.311 1.311 0 000-1.898 1.339 1.339 0 00-.95-.407c-.338 0-.677.135-.948.406L1.279 9.898A1.31 1.31 0 011.28 8L9.01.407c.27-.271.61-.407.948-.407.34 0 .678.136.95.407l1.898 1.898a1.16 1.16 0 00-.11.11c-.43.491-.393 1.286.11 1.788.27.272.61.407.949.407.339 0 .678-.136.95-.407z'/%3E%3Cpath d='M10.591 9.194l-.48.48.96.959.479-.48-.959-.959zM6.81 5.36l-.479.479.959.958.48-.479-.96-.959zM8.701 7.278l-.48.48.96.958.479-.48-.959-.958z'/%3E%3C/svg%3E");
    }

    &--pdf {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 20'%3E%3Cpath d='M10.058 12c-.67-.5-1.34-1.5-1.676-2.666.168-.834.168-1.5.168-2.167v-.333-.167V6.5c0-.333 0-.666-.168-.833 0 .167-.168 0-.335 0h-.168c-.168 0-.335.167-.503.5-.167.667-.167 2 .335 3.167-.335 1-.67 2-1.173 3C6.035 13.167 5.7 14 5.364 14.5c0-.166-.167-.166-.335 0-1.34.667-2.011 1.667-2.18 2.167V17h.839c.67 0 1.509-1 2.682-3 1.174-.166 2.85-.5 4.359-.666 1.006.5 2.012.833 2.682.833.67 0 .838-.167.838-.5.168-.333 0-.5 0-.5-.167-.5-1.173-.667-2.011-.667h-1.341c-.336-.166-.503-.333-.839-.5zM3.52 16.5c.168-.333.671-1 1.51-1.666-.671 1-1.174 1.5-1.51 1.666zM8.047 6.334s.167.166.167.333v1.167c-.335-.667-.335-1.167-.167-1.5zm-1.341 7.333l.503-1c.335-.833.67-1.5 1.005-2.167.336.834.839 1.5 1.51 2l.334.334c-1.34.166-2.347.5-3.352.833zm7.208-.167h-.168c-.335 0-.838-.166-1.34-.333 1.173 0 1.508.333 1.508.333z'/%3E%3Cpath d='M16.429 4.167L12.909.834C12.572.334 11.734 0 11.063 0H1.006C.503 0 0 .5 0 1v17.834C0 19.5.503 20 1.006 20h15.087c.671 0 1.006-.5 1.006-1V6c.168-.5-.167-1.333-.67-1.833zM11.567 1.5c.168.167.335.167.335.334l3.52 3.5s.168.333.168.5h-4.023V1.5zm4.191 17H1.508v-17h8.55v4.667c0 .5.503 1 1.006 1h4.694V18.5z'/%3E%3C/svg%3E");
    }

    &--download {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 18'%3E%3Cpath d='M8.438 14.168a.614.614 0 01-.466-.21L2.078 7.426a.63.63 0 01.47-1.05h2.36V.63c0-.348.28-.63.629-.63h5.805c.349 0 .63.282.63.63v5.747h2.36a.631.631 0 01.47 1.05l-5.899 6.535a.632.632 0 01-.466.206zM3.963 7.636l4.474 4.962 4.475-4.966h-1.57a.629.629 0 01-.63-.63V1.26H6.168v5.746c0 .349-.282.63-.63.63H3.963z'/%3E%3Cpath d='M14.134 18H2.74A2.742 2.742 0 010 15.26v-1.604a.629.629 0 111.26 0v1.603c0 .819.663 1.482 1.481 1.482h11.393c.818 0 1.482-.663 1.482-1.482v-1.603a.629.629 0 111.259 0v1.603A2.74 2.74 0 0114.134 18z'/%3E%3C/svg%3E");
    }
}