.numberpicker {
    position: relative;

    input[type="text"] {
        text-align: center;
        width: 100%;
    }

    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &[data-type="subtract"] {
            left: 0;
        }

        &[data-type="add"] {
            right: 0;
        }
    }
}