.form-container,
form.formbuilder {
    fieldset {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    legend,
    label {
        font-family: $font-family-special;
        color: $brand-primary;
        font-weight: $font-weight-bold;
    }

    legend {
        @include fluid-type($viewport-width-min, $viewport-width-max, $h3-min-font-size, $h3-font-size);
    }

    label {
        display: block;
        margin-bottom: to-em(10px, $h4-font-size);
        width: 100%;
        line-height: 1.2;
        @include fluid-type($viewport-width-min, $viewport-width-max, $h4-min-font-size, $h4-font-size);

        &.required {
            &:after {
                content:"*";
            }
        }
    }

    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    input[type=file],
    select,
    textarea {
        padding: to-em(7px, $font-size-base) to-em(12px, $font-size-base);
        width: 100%;
        height: to-em(40px, $font-size-base);
        color: $formfield-color;
        background: $formfield-bg;
        border: none;
        border-radius: $formfield-border-radius;
        line-height: 1.5;
        font-family: $font-family-base;
        appearance: none;

        .white-layout & {
            background: rgba($black, .05);
        }

        &:focus {
            color: $formfield-focus-color;
            background: $formfield-focus-bg;
            outline: 0;
            box-shadow: 0 0 0 0.25rem $formfield-focus-border-color;
        }

        &[readonly], &[disabled] {
            color: rgba($formfield-color, .5);
            background-color: rgba($white, 0.6);
        }
    }

    input[type=file] {
        padding: 0;
        overflow: hidden;

        &:not(:disabled):not([readonly]) {
            cursor: pointer;
        }

        // die beiden pseudo selektoren müssen interessanterweise getrennt sein, sonst funktionierts nicht
        &::file-selector-button {
            background: $brand-primary;
            border: none;
            margin: 0;
            margin-inline-end: to-em(12px, 20px);
            padding: 0 to-em(12px, 20px);
            height: to-em(40px, 20px);
            font-weight: $font-weight-bold;
            font-size: 20px;
            font-family: $font-family-special;
            border-radius: 0 $formfield-border-radius $formfield-border-radius 0;
            cursor: inherit;
        }

        &::-webkit-file-upload-button {
            background: $brand-primary;
            border: none;
            margin: 0;
            margin-inline-end: to-em(12px, 20px);
            padding: 0 to-em(12px, 20px);
            height: to-em(40px, 20px);
            font-weight: $font-weight-bold;
            font-size: 20px;
            font-family: $font-family-special;
            border-radius: 0 $formfield-border-radius $formfield-border-radius 0;
            cursor: inherit;
        }
    }

    input[type="checkbox"] {
        width: 25px;
        height: 25px;
        background: $formfield-bg;
        border: none;
        border-radius: $formfield-border-radius;
        appearance: none;
        color: red;

        .white-layout & {
            background: rgba(0, 0, 0, .05);
        }

        &:not(:disabled):not([readonly]) {
            cursor: pointer;
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.25rem $formfield-focus-border-color;
        }

        &:checked {
            @include updateIcon('check', '');
            background-color: $brand-primary;
            background-size: 70% auto;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    textarea {
        height: auto;
        min-height: 120px;
    }

    .checkbox,
    .radio {
        position: relative;
        padding-left: 40px;

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            left: 0;
            top: 0;
        }

        label {
            margin: 1px 0 0;
            color: $white;
            font-family: $font-family-base;
            font-weight: $font-weight-normal;
            font-size: $font-size-base;
            line-height: $line-height-base;

            .white-layout & {
                color: $black;
            }
        }
    }

    button[type="submit"].btn-lg {
        @include media-breakpoint-only(md) {
            font-size: 30px;
        }

        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }

    .cols {
        grid-column-gap: 60px;
        grid-row-gap: 40px;
        margin-bottom: $form-gutter-width;

        &.cols--2 {
            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
            }
        }
    }

    &.is-redirecting {
        opacity: .6;
        filter: blur(3px);

        * {
            pointer-events: none;
        }
    }
}

.form-group {
    margin-bottom: $form-gutter-width;

    &.has-datepicker {
        position: relative;

        &:after {
            display: block;
            content: '';
            position: absolute;
            right: 8px;
            bottom: 8px;
            width: 25px;
            height: 25px;
            @include updateIcon('calendar-alt', '');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }

    .cols & {
        margin-bottom: 0;
    }

    &.has-error {
        > input, > select, > textarea {
            box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, .75);
        }
    }
}

