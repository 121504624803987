[name="toggleMetaSubnav"] {
    display: none;
}

.meta-nav {
    flex: 1 0;
    max-width: 850px;

    @include media-breakpoint-only(md) {
        max-width: 450px;
    }

    @include media-breakpoint-down(md) {
        margin-right: 15px;
    }

    ul {
        @include reset-list;
    }

    > ul {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: nowrap;

        @include media-breakpoint-down(md) {
            justify-content: flex-end;
        }

        > li {
            position: relative;
            padding: 0 10px;
            text-align: center;

            @include media-breakpoint-down(md) {
                display: none;
            }

            &:first-child {
                padding-left: 0;

                @include media-breakpoint-down(md) {
                    display: block;
                    padding-right: 0;
                }
            }

            &:last-child {
                padding-right: 0;
            }

            &.is-active > a,
            > a:hover,
            > label:hover {
                color: $brand-primary;
            }

            a,
            > label {
                display: block;
                min-width: 24px;
                font-family: $font-family-special;
                color: $white;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
                font-size: 25px;
                cursor: pointer;
                transition: color .2s;

                @include media-breakpoint-only(md) {
                    font-size: 20px;
                }

                .icon {
                    display: block;
                    width: 100%;
                    height: 30px;
                    transition: opacity .3s;

                    @include media-breakpoint-only(md) {
                        height: 25px;
                    }

                    @include media-breakpoint-down(md) {
                        height: 24px;
                    }

                    &.icon--sally-small {
                        height: 34px;
                    }
                }

                span {
                    display: block;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }

            > a,
            > label {
                > .icon {
                    @include media-breakpoint-down(md) {
                        filter: saturate(0) brightness(0) invert(100%) opacity(1);
                    }
                }
            }

            [name="toggleMetaSubnav"]:checked ~ ul {
                opacity: 1;
                visibility: visible;
            }

            > ul {
                position: absolute;
                top: 100%;
                right: 10px;
                margin-top: 30px;
                padding: 10px 0;
                min-width: 200px;
                background: $white;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s, visibility .3s;

                @include media-breakpoint-down(md) {
                    right: -23px;
                    margin-top: 28px;
                    min-width: 160px;
                    z-index: $page-overlay--z-index + 2;
                }

                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    right: 20px;
                    bottom: 100%;
                    width: 0;
                    height: 0;
                    border-bottom: 15px solid $white;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                }

                li {
                    margin-bottom: 15px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 10px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.is-active > a {
                        color: $brand-primary;
                    }

                    > a:hover {
                        color: $black;
                        background: $brand-primary;

                        .icon {
                            filter: saturate(0) brightness(0) invert(0) opacity(1);
                        }
                    }
                }

                    a {
                        padding: 5px 20px;
                        color: $black;
                        white-space: nowrap;
                        text-align: left;
                        line-height: 30px;
                        transition: color .2s, background-color .2s;

                        @include media-breakpoint-down(md) {
                            font-size: 18px;
                            line-height: 1.2;
                        }

                        .icon {
                            display: inline-block;
                            margin-right: 15px;
                            width: 22px;
                            vertical-align: bottom;
                            transition: filter .2s;

                            @include media-breakpoint-down(md) {
                                width: 18px;
                            }
                        }
                    }
            }
        }
    }
}