.btn {
    display: inline-block;
    padding: $btn-padding;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    color: $text-color;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    border-radius: $btn-border-radius;
    box-shadow: none;
    transition: background-color .3s, color .3s, border .3s;
    
    .icon {
        position: relative;
        top: -0.125em;
        margin-right: .2em;
        width: .9em;
        height: .9em;
        line-height: $btn-line-height;
        vertical-align: bottom;
        transition: filter .3s;
    }

    &:hover {
        color: $text-color;
        text-decoration: none;
    }

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    &:active,
    &.active {

    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        opacity: $btn-disabled-opacity;
    }

    &-block {
        display: block;
    }

    // Primary Button
    &-primary {
        @include custom-button-variant($brand-primary, $brand-primary, $black, $black, $white, $white);

        .icon {
            filter: saturate(0) brightness(0) invert(0) opacity(1);
        }

        &:hover {
            .icon {
                filter: saturate(0) brightness(0) invert(100%) opacity(1);
            }
        }

        .is-highlighted:not(.white) & {
            border-color: $black;
        }

        .is-highlighted.white & {
            &:hover {
                border-color: $black;
            }
        }
    }

    // Secondary Button
    &-secondary {
        @include custom-button-variant($black, $white, $white, $brand-primary, $brand-primary, $black);

        .icon {
            filter: saturate(0) brightness(0) invert(100%) opacity(1);
        }

        &:hover {
            .icon {
                filter: saturate(0) brightness(0) invert(0) opacity(1);
            }
        }

        .is-highlighted & {
            border-color: $black;
        }

        .is-highlighted.white & {
            &:hover {
                border-color: $brand-primary;
            }
        }
    }

    // Outline Button
    &-outline {
        @include custom-button-variant(transparent, $brand-primary, $brand-primary, $brand-primary, $brand-primary, $white);

        &:hover {
            .icon {
                filter: saturate(0) brightness(0) invert(100%) opacity(1);
            }
        }

        .is-highlighted:not(.white) & {
            color: $white;
            border-color: $white;

            .icon {
                filter: saturate(0) brightness(0) invert(100%) opacity(1);
            }

            &:hover {
                background-color: $white;
                color: $black;

                .icon {
                    filter: saturate(0) brightness(0) invert(0) opacity(1);
                }
            }
        }
    }

    // Large Button
    &-lg {
        padding: 0.225em 0.75em;
        font-size: 40px;
        text-transform: uppercase;
    }

    // Small Button
    &-sm {
        font-size: $btn-font-size * 0.9;
    }

    // Spezial Button
   &-special {
       position: relative;
       margin: 0 0 0 to-em(60px, $btn-special--font-size);
       padding: to-em(10px, $btn-special--font-size) to-em(35px, $btn-special--font-size);
       width: 100%;
       @include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl), 20px, $btn-special--font-size);
       color: $white;
       border: none;
       border-radius: 0;
       transition: margin .3s;

       @include media-breakpoint-down(md) {
           padding: 9px 15px 9px 20px;
           width: auto;
           font-size: 18px;
       }

       &,
       &:after {
           background: $brand-primary;
       }

       &:hover {
           margin-left: 0;
       }

       &:before {
           display: inline-block;
           content: '';
           margin-right: to-em(14px, $btn-special--font-size);
           width: 5px;
           height: to-em(28px, $btn-special--font-size);
           @include updateIcon('arrow', '');
           background-position: center center;
           background-size: contain;
           background-repeat: no-repeat;
           vertical-align: bottom;
           filter: saturate(0) brightness(0) invert(0) opacity(1);
       }

       &:after {
           position: absolute;
           content: '';
           display: block;
           top: 0;
           bottom: 0;
           margin-left: -1px;
           width: 100vw;
           left: 100%;
       }

       &.secondary {
           color: $black;

           &,
           &:after {
               background: $white;
           }

           &:before {
               filter: none;
           }
       }

       .is-highlighted:not(.white) & {
           &:not(.secondary) {
               &,
               &:after {
                   background: $black;
               }

               &:before {
                   filter: none;
               }
           }
       }

       .is-highlighted.white & {
           color: $white;

           &.secondary {
               &,
               &:after {
                   background: $black;
               }
           }
       }
   }
}